import { Overrides } from "@material-ui/core/styles/overrides";
import { suisseIntlFont } from "theme/fonts/fonts";
import colors from "theme/palette/colors";

const overrides: Overrides = {
  MuiOutlinedInput: {
    root: {
      "&$focused $notchedOutline": {
        borderWidth: 1
      }
    }
  },
  MuiTooltip: {
    tooltip: {
      fontFamily: "SuisseIntl"
    }
  },
  MuiChip: {
    root: {
      fontFamily: "SuisseIntl"
    }
  },
  MuiTabs: {
    indicator: {
      fontFamily: "SuisseIntl"
    }
  },
  MuiTab: {
    root: {
      "&$selected": {
        fontFamily: "SuisseIntl"
      }
    }
  },
  MuiBottomNavigation: {
    root: {
      fontFamily: "SuisseIntl"
    }
  },
  MuiButtonBase: {
    root: {
      fontFamily: "SuisseIntl",
      textTransform: "none"
    }
  },
  MuiBottomNavigationAction: {
    root: {
      fontFamily: "SuisseIntl"
    },
    label: {
      fontFamily: "SuisseIntl"
    }
  },
  MuiCssBaseline: {
    "@global": {
      "@font-face": [suisseIntlFont]
    }
  },
  MuiInputBase: {
    root: {
      "&.Mui-disabled": {
        color: colors.grey.grey400
      }
    }
  }
};

export default overrides;
