import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AuditDetailDTO, AuditTemplateDetailDTO, getAllAuditTemplates, patchAuditDetail } from "app/api/auditApi";
import { Box } from "@material-ui/core";
import Question from "components/Question/Question";
import { useTranslation } from "react-i18next";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import AssignUsersMultiAutocomplete from "../../../questionnaires/utils/AssignUsersMultiAutocomplete/AssignUsersMultiAutocomplete";
import { useUserDepartments } from "app/contexts/department-context";
import { DueDate } from "components/DueDate";
import { LabelField } from "components/LabelField";
import TextEditor from "../../../questionnaires/utils/TextEditor";
import { useMetaView } from "app/contexts/meta-view-context";
import OrgunitsPathsAutocomplete from "components/OrgunitsPathsAutocomplete/OrgunitsPathsAutocomplete";
import AttachmentsOverviewOBS from "app/pages/shared/Attachments/AttachmentsOverviewOBS";
import { COLLECTIONS } from "../../../../collections";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { DateField } from "components/DateField";
import { QUESTION_TYPE } from "../../../../../components/Question/QuestionTypes";

import PrivacyExpertIcon from "assets/images/icons/dataPrivacyExpert.svg";

export const AssessmentDetails = ({
  audit,
  onUpdate
}: {
  readonly audit: Partial<AuditDetailDTO> | null;
  readonly onUpdate: () => void;
}) => {
  const { t } = useTranslation("audit_details");
  const { departmentsLoaded } = useUserDepartments();
  const { setInfo, setMeta } = useMetaView();
  const { catchAsSnackbar } = useErrorSnackbar();

  const [initialized, setInitialized] = useState<boolean | null>(null);
  const [auditData, setAuditData] = useState<Partial<AuditDetailDTO> | null>(null);
  const [templateIds, setTemplateIds] = useState<string[]>([]);
  const [templateId, setTemplateId] = useState<string>("");
  const [templates, setTemplates] = useState<AuditTemplateDetailDTO[]>([]);

  useEffect(() => {
    setAuditData(audit);
    setTemplateId(audit?.template?.id || "");
  }, [audit]);

  const noWritePermission = useMemo(
    () => !auditData?.pagesWithWrite?.includes?.("general"),
    [auditData?.pagesWithWrite]
  );

  const infoCard: Record<string, { readonly title: string; readonly text: string }> = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardContent")
      },
      associatedOrgUnitIds: {
        title: t("enteringInfoCardTitle"),
        text: t(`associatedOrgUnitIdsInfoCardContent`)
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const onFocusFieldCallback = useCallback(
    event => {
      const fieldId: string = event.target.getAttribute("id");
      if (fieldId) {
        setInfo({
          title: t("enteringInfoCardTitle"),
          text: t(`${fieldId}InfoCardContent`)
        });
      } else {
        setInfo(infoCard.entering);
      }
    },
    [infoCard.entering, setInfo, t]
  );

  const onFocusAuditScopeCallback = useCallback(() => {
    setInfo({
      title: t("enteringInfoCardTitle"),
      text: t(`auditScopeInfoCardContent`)
    });
  }, [setInfo, t]);

  /* get templates */
  useEffect(() => {
    const fetchTemplates = async () => {
      if (audit?.id) {
        const allTemplates: AuditTemplateDetailDTO[] = await getAllAuditTemplates({});
        const publishedTemplates: AuditTemplateDetailDTO[] = allTemplates.filter(({ status }) => status === "active");
        setTemplates(publishedTemplates);
        setTemplateIds((publishedTemplates || []).map(({ id }) => id));
        setInitialized(true);
      }
    };
    fetchTemplates();
  }, [audit?.id]);

  /* UPDATE AUDIT */
  const updateData = useCallback(
    async data => {
      if (auditData && auditData.id) {
        await patchAuditDetail({ id: auditData.id, payload: data, wait: data.template === undefined }).catch(
          catchAsSnackbar()
        );
        onUpdate();
      }
    },
    [auditData, onUpdate, catchAsSnackbar]
  );

  /* associatedOrgUnitIds */
  const onChangeAssociatedOrgUnitIds = useCallback(
    (associatedOrgUnitIds: string[] | null) => updateData({ associatedOrgUnitIds }),
    [updateData]
  );

  /* mainOrgUnitId */
  const onChangeMainOrgUnitId = useCallback(
    (mainOrgUnitId: string | null) => updateData({ mainOrgUnitId }),
    [updateData]
  );

  const onFocusMainOrgUnitId = useCallback(
    () =>
      setInfo({
        title: t("enteringInfoCardTitle"),
        text: t(`mainOrgUnitIdInfoCardContent`)
      }),
    [setInfo, t]
  );

  /* dataCollectionDueDate */
  const setDueDateDataCollection = useCallback(
    dataCollectionDueDate => updateData({ dataCollectionDueDate }),
    [updateData]
  );

  /* auditReportDueDate */
  const setDueDateAuditReport = useCallback(auditReportDueDate => updateData({ auditReportDueDate }), [updateData]);

  /* auditorUID */
  const setAuditorUID = useCallback(ids => updateData({ auditorUID: ids[0] }), [updateData]);

  /* furtherAuditorUIDs */
  const setAdditionalAuditorUIDs = useCallback(furtherAuditorUIDs => updateData({ furtherAuditorUIDs }), [updateData]);

  /* participantsUIDs */
  const setInvolvedUIDs = useCallback(participantsUIDs => updateData({ participantsUIDs }), [updateData]);

  /* labelIds */
  const setLabelIds = useCallback(labelIds => updateData({ labelIds }), [updateData]);
  const onFocusLabels = useCallback(() => {
    setInfo({
      title: t("labels:info_header"),
      text: t("labels:info_text_PA")
    });
  }, [setInfo, t]);

  /* auditScope */
  const [auditScope, changeAuditScope] = useState<string>("");
  useEffect(() => {
    return changeAuditScope(auditData?.auditScope || "");
  }, [auditData?.auditScope, changeAuditScope]);
  const setAuditScope = useCallback(() => updateData({ auditScope }), [auditScope, updateData]);

  const auditorUIDs = useMemo(() => (auditData?.auditorUID ? [auditData.auditorUID] : []), [auditData?.auditorUID]);

  const allowedOrgUnitIDs = useMemo(
    () =>
      [auditData?.mainOrgUnitId, ...(auditData?.associatedOrgUnitIds || [])].filter(
        (nonNull): nonNull is string => !!nonNull
      ),
    [auditData?.mainOrgUnitId, auditData?.associatedOrgUnitIds]
  );

  if (initialized && departmentsLoaded && auditData) {
    return (
      <>
        <QuestionnaireSubHeader text={t("generalInformation")} />
        <Question
          translatable={auditScope}
          translationId={"auditScope"}
          questionId="auditScope"
          questionName={t("auditScope")}
        >
          <TextEditor
            onFocus={onFocusAuditScopeCallback}
            onBlur={setAuditScope}
            onChange={changeAuditScope}
            inputValue={auditScope}
            title={t("auditScope")}
            titleComponent={undefined}
            disabled={noWritePermission}
          />
        </Question>

        <Question questionId="mainOrgUnitId" questionName={t("mainOrgUnitId")}>
          <OrgunitsPathsAutocomplete
            value={auditData.mainOrgUnitId || ""}
            onChange={onChangeMainOrgUnitId}
            label={t("mainOrgUnitId")}
            onFocus={onFocusMainOrgUnitId}
            disabled={noWritePermission}
          />
        </Question>

        <Question
          qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
          questionName={t("associatedOrgUnitIds")}
          questionId="associatedOrgUnitIds"
          info={infoCard.associatedOrgUnitIds}
          value={auditData.associatedOrgUnitIds}
          onChange={onChangeAssociatedOrgUnitIds}
          disabled={noWritePermission}
        />

        <Box display="flex">
          <Box flex={1} mr={3}>
            <Question questionName={t("dataCollectionDueDate")} questionId="dataCollectionDueDate">
              <DueDate
                date={auditData.dataCollectionDueDate || null}
                onDateChange={setDueDateDataCollection}
                label={t("dataCollectionDueDate")}
                onFocus={onFocusFieldCallback}
                disabled={noWritePermission}
              />
            </Question>
          </Box>
          <Box flex={1} ml={3}>
            <Question questionName={t("auditReportDueDate")} questionId="auditReportDueDate">
              <DateField
                date={auditData.auditReportDueDate || null}
                onDateChange={setDueDateAuditReport}
                label={t("auditReportDueDate")}
                onFocus={onFocusFieldCallback}
                disabled={noWritePermission}
              />
            </Question>
          </Box>
        </Box>

        <Box display="flex">
          <Box flex={1} mr={3}>
            <Question questionName={t("auditorUID")} questionId="auditorUID">
              <AssignUsersMultiAutocomplete
                docOrgUnitIds={allowedOrgUnitIDs}
                id={"auditorUID"}
                docAssignedUserIds={auditorUIDs}
                onDocAssignedUserIdsChanged={setAuditorUID}
                freeSolo={true}
                disableClearable={true}
                label={t("auditorUID")}
                hasMultiSelect={false}
                onFocus={onFocusFieldCallback}
                hasDefaultValue={true}
                disabled={noWritePermission}
              />
            </Question>
          </Box>
          <Box flex={1} ml={3}>
            <Question questionName={t("furtherAuditorUIDs")} questionId="furtherAuditorUIDs">
              <AssignUsersMultiAutocomplete
                docOrgUnitIds={allowedOrgUnitIDs}
                id={"furtherAuditorUIDs"}
                docAssignedUserIds={auditData.furtherAuditorUIDs || cachedEmptyArrayToNotRerender}
                onDocAssignedUserIdsChanged={setAdditionalAuditorUIDs}
                freeSolo={true}
                disableClearable={true}
                label={t("furtherAuditorUIDs")}
                hasMultiSelect={true}
                icon={<PrivacyExpertIcon />}
                onFocus={onFocusFieldCallback}
                hasDefaultValue={false}
                disabled={noWritePermission}
              />
            </Question>
          </Box>
        </Box>
        <Question questionName={t("labels:label")} questionId="labelIds">
          <LabelField
            onFocus={onFocusLabels}
            selectedIDs={auditData.labelIds}
            onSelectionChanged={setLabelIds}
            disabled={noWritePermission}
          />
        </Question>
        <Question>
          <QuestionnaireSubHeader text={t("common:attachments")} />
          <AttachmentsOverviewOBS
            docId={auditData.id}
            category={COLLECTIONS.AUDITS}
            setMeta={setMeta}
            disabled={noWritePermission}
          />
        </Question>
      </>
    );
  } else {
    return <></>;
  }
};

const cachedEmptyArrayToNotRerender: string[] = [];
