export type COLLECTION_TYPES =
  | "processes"
  | "tom"
  | "serviceProviders"
  | "dataLocations"
  | "dpias"
  | "tasks"
  | "task"
  | "recurringTasks"
  | "dataSubjectRequests"
  | "dataBreaches"
  | "risk"
  | "riskMatrix"
  | "user"
  | "notifications"
  | "deletionConcept"
  | "websites"
  | "assets"
  | "group"
  | "role"
  | "resources"
  | "audits"
  | "token"
  | "auditTemplates"
  | "personGroups"
  | "fileStorage"
  | "processorPAs"
  | "assessmentResponse"
  | "documents";

export const COLLECTIONS = {
  PROCESSES: "processes",
  TOM: "tom",
  EXTERNAL_RECIPIENTS: "serviceProviders",
  DATA_LOCATIONS: "dataLocations",
  DPIAS: "dpias",
  TASKS: "tasks",
  TASK_DETAIL: "task",
  RECURRING_TASKS: "recurringTasks",
  DATA_SUBJECT_REQUESTS: "dataSubjectRequests",
  DATA_BREACHES: "dataBreaches",
  RISK: "risk",
  RISK_MATRIX: "riskMatrix",
  USER: "user",
  NOTIFICATIONS: "notifications",
  DELETION_CONCEPT: "deletionConcept",
  WEBSITES: "websites",
  RESOURCES: "resources",
  ASSETS: "assets",
  AUDITS: "audits",
  TOKEN: "token",
  AUDIT_TEMPLATES: "auditTemplates",
  GROUP: "group",
  ROLE: "role",
  PERSON_GROUPS: "personGroups",
  FILE_STORAGE: "fileStorage",
  PROCESSOR_PAS: "processorPAs",
  DOCUMENTS: "documents",
  ASSESSMENT_RESPONSE: "assessmentResponse"
} as const satisfies Record<string, COLLECTION_TYPES>;
