import React, { useCallback, useEffect, useMemo } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { useMetaView } from "app/contexts/meta-view-context";
import DateDisplay from "components/DateDisplay";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import AssetsOverviewRow from "components/Overview/collections/assets/AssetsOverviewRow";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "../../../contexts/overview-context";
import { useUserAndTenantData } from "../../../handlers/userAndTenant/user-tenant-context";

const AssetsOverview = () => {
  const { t } = useTranslation();
  const { setInfo, setMeta } = useMetaView();

  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const dispatch = useOverviewDispatch();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("assets_overview:enteringInfoCardTitle"),
        text: t("assets_overview:enteringInfoCardText")
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const toolbarActions = useMemo(
    () => [
      { action: "sort" },
      { action: "filter" },
      { action: "add", types: [OVERVIEW_ADD_TYPE.SINGLE, OVERVIEW_ADD_TYPE.MULTIPLE] },
      { action: "mark-all-as-read" }
    ],
    []
  );

  const onRowOver = useCallback(
    item => {
      setMeta({
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.creatorUID} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        status: t("assets_overview:" + item.status.toLowerCase())
      });
    },
    [setMeta, t]
  );

  const addActions = useMemo(() => [{ action: OVERVIEW_ADD_TYPE.SINGLE }, { action: OVERVIEW_ADD_TYPE.MULTIPLE }], []);

  const rowClick = useCallback(
    async item => {
      if (item.seen === false) {
        await addToSeenItemsOfUserHook(COLLECTIONS.ASSETS, item.id);
        dispatch({
          type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
          collection: COLLECTIONS.RESOURCES,
          reloadOverview: Date.now()
        });
      }
    },
    [addToSeenItemsOfUserHook, dispatch]
  );

  return (
    <DocMetaView metaViewContent={<MetaView translationKey={"assets_overview"} />}>
      <Overview
        addActions={addActions}
        checkable={true}
        collection={COLLECTIONS.ASSETS}
        header={t("assets_overview:header")}
        onAddClose={showEnterInfo}
        onRowLeave={showEnterInfo}
        onRowOver={onRowOver}
        onRowClick={rowClick}
        CustomRowComponent={AssetsOverviewRow}
        toolbarActions={toolbarActions}
        toolbarMode="tabs"
      />
    </DocMetaView>
  );
};

export default AssetsOverview;
