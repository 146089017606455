import React, { memo, useCallback } from "react";
import { Link, Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// memoizing to prevent unnecessary rerenders
const LogoSidebarCustom = memo(
  ({ tenantName, customLogoUrl }: { readonly tenantName: string; readonly customLogoUrl: string | null }) => {
    const { t } = useTranslation("sidebar");
    const navigate = useNavigate();

    const onClickCallback = useCallback(() => {
      navigate("/");
    }, [navigate]);

    return (
      <Box
        display={"flex"}
        sx={{
          "& img": {
            width: "58px",
            height: "58px",
            objectFit: "cover",
            marginLeft: "6px",
            userSelect: "none",
            borderRadius: "8px"
          }
        }}
      >
        {customLogoUrl && <img src={customLogoUrl} alt={"Logo"} onClick={onClickCallback} />}
        {customLogoUrl && (
          <Box
            sx={{
              marginLeft: "70px",
              position: "absolute",
              width: "calc(100% - 80px)"
            }}
          >
            <Tooltip title={tenantName}>
              <Box
                sx={{
                  fontSize: "18px",
                  marginLeft: "6px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                {tenantName}
              </Box>
            </Tooltip>
            <Link
              underline="none"
              color="inherit"
              sx={{
                display: "block",
                fontSize: "10px",
                marginLeft: "6px",
                marginTop: "18px",
                "&:hover": {
                  textDecoration: "none"
                }
              }}
              target="blank"
              href="https://caralegal.eu"
            >
              {`${t("powered_by")} caralegal`}
            </Link>
          </Box>
        )}
      </Box>
    );
  }
);

LogoSidebarCustom.displayName = "LogoSidebarCustom";

export default LogoSidebarCustom;
