import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PublicLinkDTO } from "../../api/publicLinkApi";
import { useSnackbar } from "notistack";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Button from "@material-ui/core/Button";
import OrgunitsPathsAutocomplete from "../../../components/OrgunitsPathsAutocomplete/OrgunitsPathsAutocomplete";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { noUppercaseOpenSansButton } from "../../utils/standardStyles";
import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../features";
import { useTheme } from "@mui/material";

export const DSRPublicLink = ({
  dsrPublicLink,
  onRevoke,
  onUpdate
}: {
  readonly dsrPublicLink: PublicLinkDTO;
  readonly onRevoke: (publicLink: PublicLinkDTO) => Promise<void>;
  readonly onUpdate: (publicLink: Partial<Omit<PublicLinkDTO, "id">> & Pick<PublicLinkDTO, "id">) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const isCodeSnippetEnabled = useIsFeaturePresent(FEATURES.PUBLIC_REQUESTS_CODE_SNIPPET);
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState(false);
  const onUpdateWrapper = useCallback(
    async (publicLink: Partial<Omit<PublicLinkDTO, "id">> & Pick<PublicLinkDTO, "id">) => {
      setIsUpdating(true);
      try {
        await onUpdate(publicLink);
      } catch (error) {
        setIsUpdating(false);
        enqueueSnackbar(`${t("common:link_updated")}`, { variant: "error" });
        throw error;
      }
      setIsUpdating(false);
      enqueueSnackbar(
        `${t("data_subject_requests_overview:publicLink_updated", {
          name: dsrPublicLink.description || publicLink.description || t("common:noName")
        })}`,
        { variant: "success" }
      );
    },
    [enqueueSnackbar, t, dsrPublicLink.description, onUpdate]
  );

  const [description, setDescription] = useState(dsrPublicLink.description);
  const [email, setEmail] = useState(dsrPublicLink.email);
  useEffect(() => {
    setDescription(dsrPublicLink.description);
  }, [dsrPublicLink.description]);
  const onDescriptionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value);
    },
    [setDescription]
  );
  const onDescriptionBlur = useCallback(async () => {
    if (description === dsrPublicLink.description) {
      return;
    }

    await onUpdateWrapper({ description, id: dsrPublicLink.id });
  }, [onUpdateWrapper, dsrPublicLink.id, description, dsrPublicLink.description]);

  const onEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);
  const onEmailBlur = useCallback(async () => {
    if (email === dsrPublicLink.email) {
      return;
    }

    await onUpdateWrapper({ email, id: dsrPublicLink.id });
  }, [onUpdateWrapper, dsrPublicLink.id, email, dsrPublicLink.email]);
  const [responsibleOrgUnitId, setResponsibleOrgUnitId] = useState(dsrPublicLink.orgUnitId);
  useEffect(() => {
    setResponsibleOrgUnitId(dsrPublicLink.orgUnitId);
  }, [dsrPublicLink.orgUnitId]);
  const onResponsibleOrgUnitIdChange = useCallback(
    (responsibleOrgUnitId: string) => {
      setResponsibleOrgUnitId(responsibleOrgUnitId);
      return onUpdateWrapper({ orgUnitId: responsibleOrgUnitId, id: dsrPublicLink.id });
    },
    [setResponsibleOrgUnitId, onUpdateWrapper, dsrPublicLink.id]
  );

  const publicLink = useMemo(() => {
    const base64Fallback = Buffer.from(
      JSON.stringify({
        email
      })
    ).toString("base64");
    return `https://${dsrPublicLink.link}#fallback=${base64Fallback}`;
  }, [dsrPublicLink.link, email]);

  const isEmailValid = useMemo(() => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, [email]);

  const codeSnippet = useMemo(() => {
    return `<iframe frameborder="0" style="min-height: 1130px; min-width: 500px; width: 100%; height: 100%;" src="${publicLink}"></iframe>`;
  }, [publicLink]);

  const [showCodeSnippet, setShowCodeSnippet] = useState(false);
  const onShowCodeSnippet = useCallback(() => {
    setShowCodeSnippet(it => !it);
  }, []);

  const copyLinkToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(publicLink);
    enqueueSnackbar(`${t("common:link_copied")}`, { variant: "success" });
  }, [publicLink, enqueueSnackbar, t]);
  const copyCodeSnippetToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(codeSnippet);
    enqueueSnackbar(`${t("common:code_copied")}`, { variant: "success" });
  }, [enqueueSnackbar, t, codeSnippet]);

  const [isConfirmingRevoke, setIsConfirmingRevoke] = useState(false);
  const promptRevoke = useCallback(() => {
    setIsConfirmingRevoke(true);
  }, []);
  const cancelPromptRevoke = useCallback(() => {
    setIsConfirmingRevoke(false);
  }, []);

  const [isRevoked, setIsRevoked] = useState(false);
  const revokeLink = useCallback(async () => {
    await onRevoke(dsrPublicLink);
    setIsRevoked(true);
    enqueueSnackbar(
      `${t("data_subject_requests_overview:publicLink_revoked", {
        name: dsrPublicLink.description || t("common:noName")
      })}`,
      {
        variant: "success"
      }
    );
  }, [onRevoke, dsrPublicLink, enqueueSnackbar, t]);

  const theme = useTheme();

  return (
    <Collapse in={!isRevoked}>
      <Box display="flex" alignItems="center" justifyContent="space-between" gridGap={8} mb={1}>
        <Box display="flex" alignItems="center" flex="1">
          <TextField
            id={"description"}
            fullWidth={true}
            variant="outlined"
            value={description}
            onChange={onDescriptionChange}
            onBlur={onDescriptionBlur}
            placeholder={t("data_subject_requests_overview:publicLink_description_placeholder")}
          />
        </Box>
        <Box display="flex" alignItems="center" flex="1">
          <OrgunitsPathsAutocomplete
            value={responsibleOrgUnitId}
            onChange={onResponsibleOrgUnitIdChange}
            fullWidth={true}
            noIcon={true}
          />
        </Box>
      </Box>
      <Box mb={1}>
        <FormControl error={!isEmailValid} fullWidth>
          <TextField
            id={"emailSnippet"}
            fullWidth={true}
            error={!isEmailValid}
            variant="outlined"
            placeholder={t("data_subject_requests_overview:publicLink_email_placeholder")}
            onChange={onEmailChange}
            onBlur={onEmailBlur}
            value={email}
          />
          <Typography variant="caption" id="component-error-text">
            {t("data_subject_requests_overview:publicLink_modal_email_note")}
          </Typography>
        </FormControl>
      </Box>
      <Collapse in={!isConfirmingRevoke}>
        <Box display="flex" alignItems="center" justifyContent="center" gridGap={8}>
          <TextField
            id={"publicLink"}
            fullWidth={true}
            variant="outlined"
            value={isEmailValid ? publicLink : ""}
            disabled={!isEmailValid}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={!isEmailValid}>
                    <FileCopyOutlinedIcon onClick={copyLinkToClipboard} color={isEmailValid ? "primary" : "disabled"} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Box display="flex" alignItems="center" justifyContent="center">
            <Tooltip title={t("data_subject_requests_overview:publicLink_delete")}>
              <Button
                onClick={promptRevoke}
                fullWidth={true}
                style={noUppercaseOpenSansButton}
                disabled={isUpdating || !isEmailValid}
              >
                <RemoveCircleOutlineIcon
                  color={isEmailValid ? "primary" : "disabled"}
                  style={{ alignItems: "center" }}
                />
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Collapse>
      <Collapse in={isConfirmingRevoke}>
        <Box display="flex" alignItems="baseline" justifyContent="space-between" gridGap={8}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gridGap={8}
            bgcolor="alert.errorBackground"
            pl={3}
            pr={2}
            flex="1"
            borderRadius={6}
          >
            <Box display="flex" alignItems="center">
              <Typography noWrap={true}>{t("data_subject_requests_overview:publicLink_prompt_revoke")}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gridGap={8}>
              <IconButton color="primary" onClick={revokeLink}>
                <CheckIcon />
              </IconButton>
              <IconButton
                style={{
                  color: theme.palette.error.main
                }}
                onClick={cancelPromptRevoke}
              >
                <ClearIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Collapse>
      {isCodeSnippetEnabled && (
        <Box>
          <Box>
            <FormControlLabel
              control={<Checkbox onClick={onShowCodeSnippet} color="primary" />}
              label={<Typography>{t(`data_subject_requests_overview:show_code`)}</Typography>}
              disabled={isUpdating || !isEmailValid}
            />
          </Box>
          <Collapse in={showCodeSnippet}>
            <Box mb={3}>
              <TextField
                multiline
                id={"codeSnippet"}
                fullWidth={true}
                variant="outlined"
                value={isEmailValid ? codeSnippet : ""}
                disabled={isUpdating || !isEmailValid}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled={!isEmailValid}>
                        <FileCopyOutlinedIcon
                          onClick={copyCodeSnippetToClipboard}
                          color={isEmailValid ? "primary" : "disabled"}
                        />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                maxRows={5}
              />
            </Box>
          </Collapse>
        </Box>
      )}
    </Collapse>
  );
};
