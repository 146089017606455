import { Grid, Modal, Stack, Typography, Box, Paper, useTheme } from "@mui/material";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { useCallback, useEffect, useState } from "react";

export type ViewerModalType = {
  open: boolean;
  title: string;
  defocused?: boolean;
  pagination?: React.ReactNode;
  topLeftIcons?: React.ReactNode;
  topRightIcons?: React.ReactNode;
  bottomLeftButtons?: React.ReactNode;
  bottomRightButtons?: React.ReactNode;
  metaView: React.ReactNode;
  children: React.ReactNode;
  onClose: () => void;
};

export default function ViewerModal({
  open,
  title,
  defocused,
  pagination,
  topLeftIcons,
  topRightIcons,
  bottomLeftButtons,
  bottomRightButtons,
  metaView,
  children,
  onClose
}: ViewerModalType) {
  const theme = useTheme();
  const sx = {
    paper: {
      borderRadius: "14px",
      height: "calc(100vh - 80px)",
      margin: "40px auto",
      maxWidth: "1080px",
      transform: defocused ? "scale(0.85)" : "scale(1)",
      transformOrigin: "center",
      transition: "transform 0.15s ease-in-out",
      outline: "none",
      position: "relative",
      overflow: "hidden"
    },
    grid: {
      height: "100%",
      minWidth: 720
    },
    leftItem: { padding: 4, height: "100%", flex: 1, alignSelf: "stretch", paddingRight: 3 },
    rightItem: {
      borderLeft: "1px solid #E0E0E0",
      height: "100%",
      minWidth: "344px",
      maxWidth: "424px",
      flex: 1,
      alignSelf: "stretch"
    },
    stack: {
      height: 34,
      marginBottom: 1
    }
  };

  const [_open, _setOpen] = useState<boolean>(false);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const onCloseCallback = useCallback(() => {
    _setOpen(false);
    onClose();
  }, [onClose]);
  return (
    <Modal open={_open}>
      <Paper elevation={16} sx={sx.paper}>
        <Grid container sx={sx.grid}>
          <Grid item sx={sx.leftItem}>
            <Stack sx={sx.stack} spacing={2} direction="row">
              {topLeftIcons}
              {pagination}
              <Box flex={1} />
              <Stack
                direction={"row"}
                gap={"4px"}
                sx={{
                  "> *": {
                    margin: "5px"
                  },
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {topRightIcons}
                <LazySvgIcon name="Close" color={theme.palette.grey[600]} onClick={onCloseCallback} />
              </Stack>
            </Stack>
            <Box
              sx={{
                height: 32,
                marginBottom: 2
              }}
            >
              <Typography variant="h2">{title}</Typography>
            </Box>
            <Box
              style={{
                overflowY: "auto",
                width: "100%",
                height: "calc(100% - 106px)"
              }}
            >
              {children}
            </Box>
            <Stack
              spacing={2}
              direction="row"
              sx={{
                height: 40,
                marginTop: 2
              }}
            >
              {bottomLeftButtons}
              <Box flex={1} />
              {bottomRightButtons}
            </Stack>
          </Grid>
          <Grid item sx={sx.rightItem}>
            {metaView}
          </Grid>
        </Grid>
        {defocused ? (
          <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "transparent" }} />
        ) : null}
      </Paper>
    </Modal>
  );
}
