import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { QuestionProps } from "../../Question";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";

const ParticipantTypeQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  onFocus,
  onBlur,
  onChange
}: QuestionProps) => {
  const { t } = useTranslation("add_participant");

  const _value = Array.isArray(value) ? (value[0] as string) : (value as string);
  const options = ["internal", "external"];
  const getOptionLabel = useCallback(option => t(`participant_type_${option}`), [t]);

  return (
    <MultiAutocomplete
      label={questionName || t("participant_type")}
      selected={_value}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      options={options}
      disableClearable={true}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default React.memo(ParticipantTypeQuestion);
