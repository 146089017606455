import { useCallback, useEffect, useState } from "react";
import {
  OverviewController,
  OverviewItem,
  OverviewResult
} from "../components/Overview/controllers/overviewBaseController";
import { OVERVIEW_ACTIONS, OverviewDispatch, SingleOverviewContext } from "../app/contexts/overview-context";
import { COLLECTION_TYPES } from "app/collections";
import { useTranslation } from "react-i18next";

export interface CollectionParams {
  filter?: object;
  includeDone?: boolean;
  openInNewTab?: boolean;
  [key: string]: any;
  setSelectedRow?: (row: OverviewItem | null) => void;
  exportToAttachments?: (ids: string[], idToName: Map<string, string>) => Promise<void>;
  exportToPdfDialog?: (ids: string[]) => void;
  exportToXLSX?: (ids: string[]) => Promise<void>;
}

export const useOverviewData = ({
  collection,
  collectionParams,
  controller,
  dispatch,
  overviewSetup,
  paginated,
  shadowLoading = false
}: {
  collection: COLLECTION_TYPES;
  collectionParams?: CollectionParams;
  controller: OverviewController;
  dispatch: OverviewDispatch;
  overviewSetup: SingleOverviewContext;
  paginated: boolean | undefined;
  shadowLoading?: boolean;
}) => {
  const [overviewData, setOverviewData] = useState<OverviewResult | null>(null);
  const { i18n } = useTranslation("overview");
  const fetchPaginatedData = useCallback(async () => {
    const data = await controller.getPaginatedOverview(overviewSetup);
    setOverviewData(lastOverviewData => (lastOverviewData ? { ...lastOverviewData, ...data } : data));
  }, [controller, overviewSetup]);

  // non-paginated
  const fetchData = useCallback(async () => {
    const data = await controller.getOverview(overviewSetup);
    setOverviewData(lastOverviewData => (lastOverviewData ? { ...lastOverviewData, ...data } : data));
  }, [controller, overviewSetup]);

  const fetchNextPaginatedPage = useCallback(async () => {
    const data = await controller.updatePaginatedOverview(overviewSetup);
    setOverviewData(lastOverviewData => (lastOverviewData ? { ...lastOverviewData, ...data } : data));
  }, [controller, overviewSetup]);

  const reload = useCallback(
    (selectedId?: string) => {
      const reload = {
        shadowLoading,
        selectedId,
        reloadOverview: Date.now(),
        reloadMetaview: Date.now()
      };
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection, reload });
    },
    [collection, dispatch, shadowLoading]
  );

  const fetchNextPage = useCallback(() => {
    dispatch({
      type: OVERVIEW_ACTIONS.SET_LOADED,
      collection: collection,
      loaded: overviewSetup.loaded + overviewSetup.chunkSize
    });
  }, [collection, dispatch, overviewSetup.chunkSize, overviewSetup.loaded]);

  useEffect(
    function runFetch() {
      // debounce fetch to avoid multiple fetches
      const timeout = setTimeout(() => {
        if (paginated) {
          fetchPaginatedData();
        } else {
          fetchData();
        }
      }, 50);
      return () => clearTimeout(timeout);
    },
    [paginated, fetchPaginatedData, fetchData]
  );

  useEffect(() => {
    reload();
  }, [reload, i18n.language, collectionParams]);

  return {
    overviewData,
    fetchNextPaginatedPage,
    reload,
    fetchNextPage
  };
};
