import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../../../contexts/meta-view-context";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { Box, useTheme } from "@mui/material";
import { getSingleAnswerSetApi, patchAnswerSetApi, postContributorExternalUserAPi } from "app/api/assessmentApi";
import { AuditDetailDTO } from "app/api/auditApi";
import { Typography } from "@material-ui/core";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useUserDepartments } from "app/contexts/department-context";
import { toddMMyyyyDate } from "app/handlers/utility/date-helper";
import { getTextFromTextEditorJsonString } from "app/pages/questionnaires/utils/textEditorConverter";
import { AddContributorDialog } from "../../dialog/AddContributorDialog";
import colors from "theme/palette/colors";
import AssignToIcon from "assets/images/icons/assignment-ind.svg";
import GroupAddIcon from "assets/images/icons/group-add.svg";
import UserAvatar, { UserAvatarProps } from "components/UserAvatar/UserAvatar";
import UserAvatarList from "components/UserAvatar/UserAvatarList";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { ExternalUserDTO } from "app/api/user/userApi";

const AnswersetGeneral = ({ auditid, id }: { readonly auditid: string; readonly id: string }) => {
  const { t } = useTranslation("assessment_answerset");
  const { getUserNameHook, reloadTenantUsers } = useUserAndTenantData();
  const { getDepartmentWithParentName } = useUserDepartments();
  const { setInfo } = useMetaView();
  const { auth } = useAuthentication();
  const [auditData, setAuditData] = useState<Partial<AuditDetailDTO> | null>(null);
  const [auditScope, setAuditScope] = useState("");
  const [participant, setParticipant] = useState("");
  const [contributors, setContributors] = useState<string[]>();
  const [openAddContributorDialog, setOpenAddContributorDialog] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const theme = useTheme();
  const sx = {
    root: {
      padding: 1
    },
    row: {
      borderTop: `1px solid ${colors.divider}`,
      display: "flex",
      alignItems: "center",
      padding: "16px 16px 16px 0"
    },
    rowLeft: {
      alignItems: "center",
      width: "30%",
      display: "flex"
    },
    rowRight: {
      alignItems: "center",
      display: "flex"
    },
    contributors: {
      display: "flex",
      gap: 1
    },
    addContributorButton: {
      width: "24px",
      height: "24px",
      border: `1px dashed ${theme.palette.grey[500]}`
    },
    yourInformation: {
      marginTop: 2
    }
  };

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardText")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const loadAnswerSet = useCallback(async () => {
    const data = await getSingleAnswerSetApi({ auditId: auditid || "", id: id || "" });
    if (data) {
      setAuditData(data.audit);
      const newRawContent = getTextFromTextEditorJsonString(data.audit.auditScope || "");
      setAuditScope(newRawContent);
      setParticipant(data.assigneeUID || "");
      setContributors([...data.participantsUIDs]);
      setDisabled(data.status === "completed");
    }
  }, [id, auditid]);

  useEffect(() => {
    loadAnswerSet();
  }, [loadAnswerSet]);

  const furtherOrgUnits = useMemo(() => {
    if (!auditData?.associatedOrgUnitIds || auditData.associatedOrgUnitIds.length <= 0) {
      return "-";
    }

    const names = auditData.associatedOrgUnitIds
      .map(id => getDepartmentWithParentName(id))
      .filter(name => name !== undefined)
      .join(", ");
    return names;
  }, [auditData?.associatedOrgUnitIds, getDepartmentWithParentName]);

  const furtherAuditors = useMemo(() => {
    if (!auditData?.furtherAuditorUIDs || auditData.furtherAuditorUIDs.length <= 0) {
      return "-";
    }

    const names = auditData.furtherAuditorUIDs
      .map(id => getUserNameHook(id))
      .filter(name => name !== undefined)
      .join(", ");
    return names;
  }, [auditData?.furtherAuditorUIDs, getUserNameHook]);

  const onRemoveContributor = useCallback(
    async (id: string) => {
      const payload = {
        participantsUIDs: (contributors || []).filter(_id => _id !== id)
      };
      await patchAnswerSetApi({ auditId: auditid, id, payload });
      loadAnswerSet();
    },
    [auditid, contributors, loadAnswerSet]
  );

  const onShowContributorAddDialog = useCallback(() => setOpenAddContributorDialog(true), []);
  const onCloseContributorAddDialog = useCallback(() => setOpenAddContributorDialog(false), []);

  /* PARTICIPANT */
  const participantEl = useMemo(() => {
    return (
      <Box sx={sx.row}>
        <Box sx={sx.rowLeft}>
          <AssignToIcon />
          <Box mr={2} />
          <Typography>{t("add_participant:participant")}</Typography>
        </Box>
        <Box sx={sx.rowRight}>
          <UserAvatar userId={participant} size="small" />
          <Box mr={2} />
          <Typography variant="body2">{getUserNameHook(participant)}</Typography>
        </Box>
      </Box>
    );
  }, [participant, getUserNameHook, sx.row, sx.rowLeft, sx.rowRight, t]);

  const contributorsEl = useMemo(() => {
    const avatars = (contributors || []).map(
      (contributorId: string) =>
        ({
          userId: contributorId,
          size: "small",
          color: colors.blue.blue400,
          allowRemove: true
        }) as UserAvatarProps
    );
    return (
      <>
        <Box sx={sx.row}>
          <Box sx={sx.rowLeft}>
            <GroupAddIcon />
            <Box mr={2} />
            <Typography>{t("add_participant:Contributors")}</Typography>
          </Box>
          <Box sx={{ ...sx.rowRight, ...sx.contributors }}>
            <UserAvatarList
              avatars={avatars}
              disabled={disabled || auth?.uid !== participant}
              onRemove={onRemoveContributor}
              onAdd={onShowContributorAddDialog}
            />
          </Box>
        </Box>
        <Box sx={sx.row}>
          <Box sx={sx.rowLeft} />
          <Box sx={sx.rowRight} />
        </Box>
      </>
    );
  }, [
    disabled,
    contributors,
    participant,
    auth?.uid,
    onRemoveContributor,
    onShowContributorAddDialog,
    sx.contributors,
    sx.row,
    sx.rowLeft,
    sx.rowRight,
    t
  ]);

  const onConfirmAddInternalContributor = useCallback(
    async (userId: string) => {
      onCloseContributorAddDialog();
      const payload = {
        participantsUIDs: [...(contributors || []), userId]
      };
      await patchAnswerSetApi({ auditId: auditid, id, payload });
      loadAnswerSet();
    },
    [auditid, id, contributors, loadAnswerSet, onCloseContributorAddDialog]
  );

  const onConfirmAddExternalContributor = useCallback(
    async (externalUser: ExternalUserDTO) => {
      await postContributorExternalUserAPi({ auditId: auditid, answersetId: id || "", payload: externalUser });
      reloadTenantUsers();
      onCloseContributorAddDialog();
      loadAnswerSet();
    },
    [auditid, id, loadAnswerSet, onCloseContributorAddDialog, reloadTenantUsers]
  );

  return (
    <Box>
      <Box>
        {participantEl}
        {contributorsEl}
      </Box>
      <AddContributorDialog
        open={openAddContributorDialog}
        onCancel={onCloseContributorAddDialog}
        onConfirmInternal={onConfirmAddInternalContributor}
        onConfirmExternal={onConfirmAddExternalContributor}
      />
      <Box mb={4} mt={2}>
        <Typography align="left" style={{ lineHeight: "32px", fontWeight: "bold", fontSize: "20px" }}>
          {t("pagination:general")}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={6}>
        <Box flex={1}>
          <Typography align="left" variant="body2" style={{ lineHeight: "24px", fontWeight: "bold" }}>
            {t("audit_details:startDate")}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography align="left" variant="body2" style={{ lineHeight: "24px", fontWeight: "bold" }}>
            {t("audit_details:auditReportDueDate")}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" gap={6}>
        <Box flex={1}>
          <Typography align="left" variant="inherit">
            {toddMMyyyyDate(auditData?.createdAt)}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography align="left" variant="inherit">
            {auditData?.auditReportDueDate ? toddMMyyyyDate(auditData.auditReportDueDate) : "-"}
          </Typography>
        </Box>{" "}
      </Box>

      <Box mt={3}>
        <Typography align="left" style={{ lineHeight: "36px", fontSize: "16px" }}>
          {t("audit_details:auditScope")}
        </Typography>
      </Box>
      <Box>
        <Typography align="left" variant="inherit">
          {auditScope || "-"}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" gap={6} mt={3}>
        <Box flex={1}>
          <Typography align="left" variant="body2" style={{ lineHeight: "24px", fontWeight: "bold" }}>
            {t("pa_general:responsible_department")}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography align="left" variant="body2" style={{ lineHeight: "24px", fontWeight: "bold" }}>
            {t("pa_general:furtherDepartments")}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" gap={6}>
        <Box flex={1} style={{ alignSelf: "flex-start" }}>
          <Typography align="left" variant="inherit">
            {getDepartmentWithParentName(auditData?.mainOrgUnitId || "")}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography align="left" variant="inherit">
            {furtherOrgUnits}
          </Typography>
        </Box>{" "}
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" gap={6} mt={3}>
        <Box flex={1}>
          <Typography align="left" variant="h5" style={{ lineHeight: "24px" }}>
            {t("audit_details:auditorUID")}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography align="left" variant="h5" style={{ lineHeight: "24px" }}>
            {t("audit_details:furtherAuditorUIDs")}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" gap={6} mb={3}>
        <Box flex={1} style={{ alignSelf: "flex-start" }}>
          <Typography align="left" variant="inherit">
            {getUserNameHook(auditData?.auditorUID || "")}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography align="left" variant="inherit">
            {furtherAuditors}
          </Typography>
        </Box>{" "}
      </Box>
    </Box>
  );
};

export default AnswersetGeneral;
