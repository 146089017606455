import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import HouseIcon from "@mui/icons-material/House";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { ANSWERSET_TYPE } from "app/api/assessmentApi";
import { ExternalUserDTO, UserDTO } from "app/api/user/userApi";

export interface AddParticipantDialogProps {
  readonly open: boolean;
  readonly errors?: Record<string, string>;
  readonly onCancel?: () => void;
  readonly onConfirmInternal: (userId: string) => Promise<void>;
  readonly onConfirmExternal: (externalUser: ExternalUserDTO) => Promise<void>;
}

export const AddContributorDialog = ({
  open,
  errors,
  onCancel,
  onConfirmInternal,
  onConfirmExternal
}: AddParticipantDialogProps) => {
  const { t } = useTranslation("add_participant");

  const [type, setType] = useState<ANSWERSET_TYPE>(ANSWERSET_TYPE.INTERNAL);
  const [externalUser, setExternalUser] = useState<ExternalUserDTO>({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    inviteMessage: ""
  });
  const [internalUser, setInternalUser] = useState<UserDTO>({
    orgUnitId: "",
    id: ""
  });
  const [isValid, setIsValid] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const clearCallback = useCallback(() => {
    setType(ANSWERSET_TYPE.INTERNAL);
    setExternalUser({ firstName: "", lastName: "", email: "", companyName: "", inviteMessage: "" });
    setInternalUser({ orgUnitId: "", id: "" });
    setIsValid(false);
    setInProgress(false);
  }, []);

  useEffect(() => {
    if (type === ANSWERSET_TYPE.INTERNAL) {
      setIsValid(!!internalUser.orgUnitId && !!internalUser.id);
    } else if (type === ANSWERSET_TYPE.EXTERNAl) {
      setIsValid(!!externalUser.firstName && !!externalUser.lastName && !!externalUser.email);
    } else {
      setIsValid(false);
    }
  }, [
    internalUser.orgUnitId,
    externalUser.email,
    externalUser.firstName,
    externalUser.lastName,
    type,
    internalUser.id
  ]);

  useEffect(() => {
    if (open) {
      clearCallback();
      setInProgress(false);
    }
  }, [clearCallback, open]);

  useEffect(() => {
    if (errors && Object.keys(errors).length) {
      setInProgress(false);
    }
  }, [errors]);

  const onUpdateAnswerSetCallback = useCallback((value, valueName: string | undefined) => {
    if (valueName !== undefined) {
      setInternalUser(current =>
        current ? { ...current, [valueName]: Array.isArray(value) ? value[0] : value } : current
      );
    }
  }, []);

  const onUpdateExternalUserCallback = useCallback((value, valueName: string | undefined) => {
    if (valueName !== undefined) {
      setExternalUser(current =>
        current ? { ...current, [valueName]: Array.isArray(value) ? value[0] : value } : current
      );
    }
  }, []);

  const onConfirmCallback = useCallback(async () => {
    setInProgress(true);
    // for External anwers set we have to create a new user
    if (type === ANSWERSET_TYPE.EXTERNAl) {
      onConfirmExternal(externalUser);
    } else if (type === ANSWERSET_TYPE.INTERNAL && internalUser?.id) {
      onConfirmInternal(internalUser.id);
    } else {
      onCancel?.();
    }
  }, [type, internalUser.id, externalUser, onConfirmExternal, onConfirmInternal, onCancel]);

  const onCancelCallback = useCallback(() => {
    clearCallback();
    onCancel?.();
  }, [clearCallback, onCancel]);

  /* INTERNAL ANSWERSET */
  const internalAnswerSetEl = useMemo(() => {
    if (type === ANSWERSET_TYPE.INTERNAL) {
      const { orgUnitId, id } = internalUser;
      return (
        <>
          <Question
            qType={QUESTION_TYPE.AFFECTED_ORG_UNITS}
            value={orgUnitId}
            valueName={"orgUnitId"}
            onChange={onUpdateAnswerSetCallback}
            questionName={t("internal_participant_org_unit")}
            textFieldIcon={<HouseIcon color="primary" />}
            pt={3}
            pb={0}
          />
          <Question
            qType={QUESTION_TYPE.ASSIGNED_TO_USER}
            disabled={!orgUnitId}
            value={id || ""}
            valueName={"id"}
            onChange={onUpdateAnswerSetCallback}
            questionName={t("internal_contributor_user")}
            textFieldIcon={<AssignmentIndIcon color="primary" />}
            orgUnitIds={orgUnitId ? [orgUnitId] : []}
            userDepartmentBound={true}
            pb={0}
          />
        </>
      );
    }
    return <></>;
  }, [type, internalUser, onUpdateAnswerSetCallback, t]);

  /* EXTERNAL USER */
  const externalUserEl = useMemo(() => {
    if (type === ANSWERSET_TYPE.EXTERNAl) {
      return (
        <>
          <Question
            qType={QUESTION_TYPE.TEXT_AREA}
            value={externalUser?.firstName || ""}
            valueName={"firstName"}
            onChange={onUpdateExternalUserCallback}
            questionName={t("fields:first_name")}
            pt={3}
            pb={0}
          />
          <Question
            qType={QUESTION_TYPE.TEXT_AREA}
            value={externalUser?.lastName || ""}
            valueName={"lastName"}
            onChange={onUpdateExternalUserCallback}
            questionName={t("fields:last_name")}
            pt={3}
            pb={0}
          />
          <Question
            qType={QUESTION_TYPE.EMAIL}
            value={externalUser?.email || ""}
            valueName={"email"}
            onChange={onUpdateExternalUserCallback}
            questionName={t("fields:email")}
            error={Boolean((errors && errors["email"]) || false)}
            helperText={(errors && errors["email"]) || ""}
            pt={3}
            pb={0}
          />
          <Question
            qType={QUESTION_TYPE.TEXT_AREA}
            value={externalUser?.companyName || ""}
            valueName={"companyName"}
            onChange={onUpdateExternalUserCallback}
            questionName={t("fields:tenant_name")}
            pt={3}
            pb={0}
          />
          <Question
            qType={QUESTION_TYPE.TEXT_AREA}
            value={externalUser?.inviteMessage || ""}
            valueName={"inviteMessage"}
            onChange={onUpdateExternalUserCallback}
            questionName={"Invite message"}
            pt={6}
            pb={0}
          />
        </>
      );
    }
    return <></>;
  }, [
    type,
    externalUser?.firstName,
    externalUser?.lastName,
    externalUser?.email,
    externalUser?.companyName,
    externalUser?.inviteMessage,
    onUpdateExternalUserCallback,
    t,
    errors
  ]);

  return (
    <Dialog open={open} onClose={onCancelCallback} maxWidth={"xs"} fullWidth={true}>
      <DialogTitle variant="h4">
        <Typography variant="h4">{t("contributorTitle")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box mt={1}>
          <Question
            qType={QUESTION_TYPE.PARTICIPIANT_TYPE}
            questionName={t("contributor_type")}
            value={type || ANSWERSET_TYPE.INTERNAL}
            valueName={"type"}
            onChange={setType}
            pb={0}
            pt={0}
          />
          {internalAnswerSetEl}
          {externalUserEl}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelCallback} color="primary" variant="outlined">
          {t("cancel_text")}
        </Button>
        <Button onClick={onConfirmCallback} color="primary" disabled={!isValid || inProgress} variant="contained">
          {t("contributor_confirm_text")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
