import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircle, Comment, Forum, HelpOutline, History } from "@material-ui/icons";
import MetaViewTabs from "components/MetaView/MetaViewTabs";
import OwlitIconSrc from "assets/images/owlit/owlit_logo.png";
import MetaViewAssistantTab from "./tabs/MetaViewAssistantTab";
import MetaViewTasksTab from "./tabs/MetaViewTasksTab";
import { Box } from "@material-ui/core";
import { useMetaView } from "app/contexts/meta-view-context";
import MetaViewCommentsTab from "./tabs/MetaViewCommentsTab";
import { OwlitMetaView } from "components/OwlitMetaView";
import MetaViewRemarksTab from "./tabs/MetaViewRemarksTab";
import { useQueryParamByKey } from "app/router/router-custom-hooks";
import CustomAlert from "../CustomAlert/CustomAlert";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import QuestionnaireVersionHistory from "app/pages/questionnaires/utils/QuestionnaireVersionHistory/QuestionnaireVersionHistory";

export type META_VIEW_TABS_TYPES = "assistant" | "todos" | "comments" | "remarks" | "history" | "owlit";
export const META_VIEW_TABS = {
  ASSISTANT: "assistant",
  TODOS: "todos",
  COMMENTS: "comments",
  REMARKS: "remarks",
  HISTORY: "history",
  OWLIT: "owlit"
} as const satisfies Record<string, META_VIEW_TABS_TYPES>;

export interface MetaViewProps {
  readonly pageId?: string;
  readonly docId?: string;
  readonly docName?: string;
  readonly collection?: string;
  readonly translationKey?: string;
  readonly showHistory?: boolean;
  readonly tabs?: META_VIEW_TABS_TYPES[];
  readonly editable?: boolean;
  readonly children?: React.ReactNode;
}

export interface MetaViewTabProps {
  readonly tabId: META_VIEW_TABS_TYPES;
  readonly title: string;
  readonly content: React.ReactNode;
  readonly icon: React.ReactNode;
}

const MetaView = ({ pageId, docId, docName, collection, translationKey, tabs, editable, children }: MetaViewProps) => {
  const { t } = useTranslation();

  const { selectedTab, setSelectedTab, setQuestionId } = useMetaView();

  const paramQuestionId = useQueryParamByKey("questionId");
  const paramCommentId = useQueryParamByKey("commentId");
  const paramTaskId = useQueryParamByKey("taskId");

  useEffect(() => {
    if (paramQuestionId) {
      setQuestionId(paramQuestionId);
    }
  }, [paramQuestionId, setQuestionId]);

  useEffect(() => {
    if (paramCommentId) {
      setSelectedTab(META_VIEW_TABS.COMMENTS);
    } else if (paramTaskId) {
      setSelectedTab(META_VIEW_TABS.TODOS);
    }
  }, [paramCommentId, paramTaskId, setSelectedTab]);

  const assistantContentEl = useMemo(
    () => <MetaViewAssistantTab editable={editable} translationKey={translationKey} />,
    [editable, translationKey]
  );
  const tasksContentEl = useMemo(
    () => <MetaViewTasksTab collection={collection || ""} pageId={pageId} docId={docId} />,
    [collection, pageId, docId]
  );
  const commentsContentEl = useMemo(
    () => (
      <MetaViewCommentsTab
        collection={collection || ""}
        docId={docId || ""}
        docName={docName || ""}
        pageId={pageId || ""}
      />
    ),
    [collection, docId, docName, pageId]
  );
  const remarksContentEl = useMemo(() => <MetaViewRemarksTab />, []);
  const historyContentEl = useMemo(() => <QuestionnaireVersionHistory />, []);
  const owlitContentEl = useMemo(() => <OwlitMetaView />, []);

  const allTabs = useMemo(
    () => ({
      [META_VIEW_TABS.ASSISTANT]: {
        tabId: META_VIEW_TABS.ASSISTANT,
        title: t("metaview_tabs:assistant"),
        content: assistantContentEl,
        icon: <HelpOutline />
      },
      [META_VIEW_TABS.TODOS]: {
        tabId: META_VIEW_TABS.TODOS,
        title: t("metaview_tabs:tasks"),
        content: tasksContentEl,
        icon: <CheckCircle />
      },
      [META_VIEW_TABS.COMMENTS]: {
        tabId: META_VIEW_TABS.COMMENTS,
        title: t("metaview_tabs:comments"),
        content: commentsContentEl,
        icon: <Forum />
      },
      [META_VIEW_TABS.REMARKS]: {
        tabId: META_VIEW_TABS.REMARKS,
        title: t("metaview_tabs:remarks"),
        content: remarksContentEl,
        icon: <Comment />
      },
      [META_VIEW_TABS.HISTORY]: {
        tabId: META_VIEW_TABS.HISTORY,
        title: t("metaview_tabs:version_history"),
        content: historyContentEl,
        icon: <History />
      },
      [META_VIEW_TABS.OWLIT]: {
        tabId: META_VIEW_TABS.OWLIT,
        title: t("metaview_tabs:owlit"),
        content: owlitContentEl,
        icon: <img src={OwlitIconSrc} style={{ width: "26px" }} />
      }
    }),
    [assistantContentEl, commentsContentEl, historyContentEl, owlitContentEl, remarksContentEl, t, tasksContentEl]
  );

  const activeTabs = tabs ? tabs.map(tabId => allTabs[tabId]) : [allTabs[META_VIEW_TABS.ASSISTANT]];

  const { auth } = useAuthentication();
  const readOnlyUser =
    auth?.permissions && auth.permissions.length > 0 && auth.permissions.every(it => it.includes("_read_"));
  const globalMetaAlert = readOnlyUser ? (
    <Box mt={0}>
      <CustomAlert severity={"warning"} style={{ justifyContent: "center" }}>
        <Box mt={0}>
          <b>{t("metaview_tabs:readOnlyAlert")}</b>
        </Box>
      </CustomAlert>
    </Box>
  ) : (
    <></>
  );

  return (
    <Box position="relative" height="100%" overflow="hidden">
      {globalMetaAlert}
      <MetaViewTabs tabs={activeTabs} selected={selectedTab || META_VIEW_TABS.ASSISTANT} onTabChange={setSelectedTab}>
        {children}
      </MetaViewTabs>
    </Box>
  );
};

export default React.memo(MetaView);
