import { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar, Box, Tooltip, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { COLLECTIONS, COLLECTION_TYPES } from "app/collections";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import AssignToNoBody from "../../../../assets/images/tasks/assign-to-no-body.svg";

const useStyles = makeStyles(theme => ({
  container: {
    height: "24px",
    borderRadius: "12px"
  },
  hoverGrayBg: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100]
    }
  },
  containerFullName: {
    display: "flex",
    alignItems: "center",
    height: "34px",
    paddingLeft: "8px",
    paddingRight: "8px",
    marginLeft: -theme.spacing(1)
  },
  root: {
    height: "24px",
    width: "24px",
    textTransform: "uppercase",
    fontSize: "10px",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff"
  },
  addUserDashed: {
    border: "1px dashed",
    fontWeight: 600,
    fontSize: "10px",
    letterSpacing: "0.5px",
    borderRadius: "12px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderColor: theme.palette.grey[400],
    color: theme.palette.grey[400]
  }
}));

interface TaskOverviewRowAvatarProps {
  readonly collection: COLLECTION_TYPES;
  readonly onChange: (userId: string) => void;
  readonly readOnly?: boolean;
  readonly showAddUser?: boolean;
  readonly userId: string;
  readonly userNameDisplay?: string;
}
const TaskOverviewRowAssignUser = ({
  collection,
  onChange,
  readOnly,
  showAddUser,
  userId,
  userNameDisplay
}: TaskOverviewRowAvatarProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const { getUserNameHook } = useUserAndTenantData();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  useEffect(() => {
    setSelectedId(userId);
  }, [userId]);

  const openMenuCallback = useCallback(() => {
    if (readOnly) {
      return;
    }
    setOpen(true);
  }, [readOnly]);

  const closeMenuCallback = useCallback(() => {
    setOpen(false);
  }, []);

  const onSaveCallback = useCallback(() => {
    onChange(selectedId);
    closeMenuCallback();
  }, [closeMenuCallback, onChange, selectedId]);

  const onChangeCallback = useCallback((ids: string[]) => {
    setSelectedId(ids[0] || "");
  }, []);

  const assignTaskModalBody = (
    <Question qType={QUESTION_TYPE.ASSIGNED_TO_USER} value={selectedId} onChange={onChangeCallback} />
  );

  const assignTaskModalButtons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: closeMenuCallback
      },
      {
        confirmButton: true,
        disabled: false,
        title: t("assign"),
        variant: "contained",
        color: "primary",
        size: "medium",
        onClick: onSaveCallback
      }
    ],
    [closeMenuCallback, onSaveCallback, t]
  );

  const iconEl = useMemo(() => {
    if (userId) {
      return (
        <Tooltip title={t("changeAssignee")} disableHoverListener={readOnly}>
          <Avatar className={cls.root} onClick={openMenuCallback}>
            {getUserNameHook(userId)?.substring(0, 2).toUpperCase()}
          </Avatar>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t("changeAssignee")}>
          <Box onClick={openMenuCallback}>
            <AssignToNoBody />
          </Box>
        </Tooltip>
      );
    }
  }, [cls.root, getUserNameHook, openMenuCallback, readOnly, t, userId]);

  if (!userId && !showAddUser) {
    return <></>;
  }

  return (
    <>
      <Box
        display="flex"
        onClick={openMenuCallback}
        className={[
          cls.container,
          ...(userNameDisplay && userId ? [cls.containerFullName] : []),
          ...(readOnly ? [] : [cls.hoverGrayBg])
        ].join(" ")}
        style={!readOnly ? { cursor: "pointer" } : {}}
      >
        {userId && iconEl}
        {userId && userNameDisplay && (
          <Box ml={1} display="flex" alignItems="center">
            {userNameDisplay}
          </Box>
        )}
        {!userId && showAddUser && (
          <Box display="flex" alignItems="center" className={cls.addUserDashed}>
            {`+ ${t("add_user").toUpperCase()}`}
          </Box>
        )}
      </Box>
      <ConfirmationModal
        modalOpen={open}
        onClose={closeMenuCallback}
        modalTitle={t("assign_task")}
        modalBody={assignTaskModalBody}
        buttons={assignTaskModalButtons}
        variant="info"
        modalText={""}
      />
    </>
  );
};

export default TaskOverviewRowAssignUser;
