import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import DocView from "components/DocView/DocView";
import { useTranslation } from "react-i18next";
import MetaView, { META_VIEW_TABS } from "components/MetaView/MetaView";
import { useMetaView } from "app/contexts/meta-view-context";
import { useParams } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import { AuditDetailDTO } from "app/api/auditApi";
import { COLLECTIONS } from "app/collections";
import DocumentNotFound from "app/pages/shared/DocumentNotFound/DocumentNotFound";
import CompletedIcon from "../../../../../../assets/images/assessment/statuses/completed.svg";
import DraftIcon from "../../../../../../assets/images/assessment/statuses/draft.svg";
import EditIcon from "../../../../../../assets/images/assessment/statuses/in-progress.svg";
import AssessmentIcon from "../../../../../../assets/images/assessment/assessment.svg";
import SelfAssessmentIcon from "../../../../../../assets/images/assessment/self-assessment.svg";
import { ANSWERSET_STATUS } from "app/pages/audits/audit/AuditTypes";
import { AnswersetPageButtons, AnswersetPageStepper } from "./AnswersetPagination";
import AnswersetGeneral from "./AnswersetGeneral";
import AnswersetConfirmation from "./AnswersetConfirmation";
import AnswersetQuestionnaire from "./AnswersetQuestionnaire";
import { getSingleAnswerSetApi, patchAnswerSetApi } from "app/api/assessmentApi";
import { useAuthentication } from "app/handlers/authentication/authentication-context";

export const AssessmentSubmitButton = ({
  readonly,
  onApproved
}: {
  readonly readonly?: boolean;
  readonly onApproved?: () => void;
}) => {
  const { t } = useTranslation();
  const { auditid, id } = useParams();
  const onSubmitCallback = useCallback(async () => {
    const payload = {
      status: "completed"
    };
    await patchAnswerSetApi({ auditId: auditid || "", id: id || "", payload });
  }, [auditid, id]);

  return (
    <Button variant="contained" color="primary" onClick={onSubmitCallback} disabled={readonly}>
      {t("pagination:finish")}
    </Button>
  );
};

export default function AnswersetPage() {
  const { auditid, id, page } = useParams();
  const { auth } = useAuthentication();
  const { t } = useTranslation("assessment_answerset");
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const [docName, setDocName] = useState("");
  const [docStatus, setDocStatus] = useState("");
  const [participant, setParticipant] = useState("");
  const [auditData, setAuditData] = useState<Partial<AuditDetailDTO> | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { setInfo } = useMetaView();

  const loadAnswerSet = useCallback(async () => {
    const data = await getSingleAnswerSetApi({ auditId: auditid || "", id: id || "" });
    if (!data) {
      setDocumentNotFound(true);
    } else {
      setAuditData(data.audit);
      setDocName(data.title);
      setDocStatus(data.status);
      setParticipant(data.assigneeUID || "");
    }
    setIsLoading(false);
  }, [id, auditid]);

  useEffect(() => {
    loadAnswerSet();
  }, [loadAnswerSet]);

  const defaultInfo = useMemo(
    () =>
      id
        ? {
            title: t("roles_overview:roleDetailsInfoTitle"),
            text: t("roles_overview:roleDetailsInfoText")
          }
        : {
            title: t("roles_overview:addNewRoleInfoTitle"),
            text: t("roles_overview:addNewRoleInfoText")
          },
    [id, t]
  );

  useEffect(() => {
    setInfo(defaultInfo);
  }, [setInfo, defaultInfo]);

  const onInfoTextReset = useCallback(() => {
    setInfo(defaultInfo);
  }, [setInfo, defaultInfo]);

  if (documentNotFound) {
    return <DocumentNotFound collection={COLLECTIONS.ASSESSMENT_RESPONSE} />;
  }
  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          collection={COLLECTIONS.ASSESSMENT_RESPONSE}
          docId={id}
          docName={docName}
          pageId={page || "general"}
          tabs={metaViewTabIds}
          translationKey={"assessment_answerset"}
        />
      }
      loading={isLoading}
    >
      <AnswerSetDocView key={page} docName={docName} docStatus={docStatus} isParticipant={auth?.uid === participant}>
        <Box>
          <Box mt={4}>
            {page === "general" && <AnswersetGeneral id={id || ""} auditid={auditid || ""} />}
            {page === "questionnaire" && <AnswersetQuestionnaire id={id || ""} auditid={auditid || ""} />}
            {page === "confirmation" && <AnswersetConfirmation readOnly={docStatus === "completed"} />}
          </Box>
          <Box px={6}>
            <AnswersetPageButtons
              rightArea={
                page === "confirmation" && (
                  <Box display="flex" alignItems="center" gridGap={8} justifyContent="space-between">
                    <AssessmentSubmitButton readonly={docStatus === "completed"} />
                  </Box>
                )
              }
            />
          </Box>
        </Box>
      </AnswerSetDocView>
    </DocMetaView>
  );
}
const metaViewTabIds = [META_VIEW_TABS.ASSISTANT];
export const AnswerSetDocView = ({
  children,
  docName,
  docStatus,
  isParticipant
}: {
  children: React.ReactNode;
  docName: string;
  docStatus: string;
  isParticipant: boolean;
}) => {
  const { t } = useTranslation("assessment_answerset");
  const docProperties = useMemo(() => {
    return [
      { icon: <AssessmentIcon />, label: t("questionnaire_four_five_page:title") },
      isParticipant
        ? { icon: <SelfAssessmentIcon />, label: t("add_participant:participant") }
        : { icon: <SelfAssessmentIcon />, label: t("add_participant:internal_contributor_user") }
    ];
  }, [t, isParticipant]);
  const statusIconMapper = useMemo(
    () => ({
      [ANSWERSET_STATUS.COMPLETED]: <CompletedIcon />,
      [ANSWERSET_STATUS.INPROGRESS]: <EditIcon />,
      [ANSWERSET_STATUS.PENDING]: <DraftIcon />
    }),
    []
  );
  const statuses = useMemo(() => {
    return Object.values(ANSWERSET_STATUS).map(status => ({
      id: status,
      label: t(`answerset_status:${status.toLowerCase()}`),
      icon: statusIconMapper[status]
    }));
  }, [statusIconMapper, t]);
  return (
    <DocView
      docProperties={docProperties}
      header={docName}
      pagination={<AnswersetPageStepper />}
      statusId={docStatus}
      statuses={statuses}
      disabled={true}
    >
      {children}
    </DocView>
  );
};
