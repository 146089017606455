import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar, Box, makeStyles, Tooltip } from "@material-ui/core";
import { CheckCircle, CheckCircleOutline, Comment, Forum } from "@material-ui/icons";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import QuestionTitle from "components/QuestionTitle/QuestionTitle";
import TranslateOutlinedIcon from "@material-ui/icons/TranslateOutlined";
import { genericTasksFilter } from "app/handlers/tasksHandler";
import { useParams } from "react-router-dom";
import { FEATURES } from "app/features";
import { getTextFromTextEditorJsonString } from "app/pages/questionnaires/utils/textEditorConverter";
import { useTranslation } from "react-i18next";
import { useTasks } from "../../app/contexts/tasks-context";
import { useAuditRemarks } from "../../app/contexts/remarks-context";
import { useMetaView } from "app/contexts/meta-view-context";
import { useIsFeaturePresent } from "../../hook/useIsFeaturePresent";
import { QUESTION_ACTION, QUESTION_ACTION_TYPES, QUESTION_CHIP, QUESTION_CHIP_TYPES, QuestionProps } from "./Question";
import { TranslationPopper, TranslationResult } from "./TranslationQuestion";
import { META_VIEW_TABS } from "components/MetaView/MetaView";
import { useUserAndTenantData } from "../../app/handlers/userAndTenant/user-tenant-context";
import { useWhereIAmContext } from "../../app/contexts/where-i-am-context";
import { usePathName } from "../../app/router/router-custom-hooks";
import { updateWhereIAmContinuously } from "../../app/handlers/whereIAmHandler";
import { useAuthentication } from "../../app/handlers/authentication/authentication-context";
import { AvatarGroup } from "@material-ui/lab";
import { useComments } from "app/api/commentApi";
import { useAuditActionTasks } from "app/contexts/audit-action-tasks-context";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    cursor: "pointer"
  },
  actionContainer: {
    borderRight: "3px solid transparent"
  },
  focused: {
    borderRight: "3px solid #82a5ff"
  },
  action: {
    position: "absolute",
    right: -49,
    top: 15,
    "& button": {
      width: 36,
      height: 36
    }
  },
  comments: {
    display: "flex",
    fontSize: "0.8em",
    position: "absolute",
    right: 0,
    top: 0
  },
  chips: {
    display: "flex",
    cursor: "pointer"
  },
  number: {
    lineHeight: "20px",
    marginLeft: 2
  },
  cursorAuto: {
    cursor: "auto"
  },
  avatar: {
    "& .MuiAvatar-root": {
      height: 23.5,
      width: 23.5,
      fontSize: 11,
      lineHeight: 5,
      cursor: "pointer",
      border: "0.5px solid #fafafa"
    }
  }
}));

interface QuestionAction {
  readonly id: QUESTION_ACTION_TYPES;
  readonly name: string;
  readonly icon: React.ReactNode;
  readonly handle: (event?: any) => void;
}

const CommentsChip = ({ questionId }: { readonly questionId: string }) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  // const [number, setNumber] = useState<number>(0);
  const { setShowTaskDetails, setSelectedTab } = useMetaView();
  const params = useParams();
  const comments = useComments({ documentId: params.id as string, namespace: questionId });
  const number = React.useMemo(() => comments.data?.commentModels?.length || 0, [comments.data?.commentModels]);

  const onClicked = useCallback(() => {
    setSelectedTab(META_VIEW_TABS.COMMENTS);
    setShowTaskDetails(false);
  }, [setSelectedTab, setShowTaskDetails]);

  return number > 0 ? (
    <Tooltip title={t("tasks_page:go_to_comments")}>
      <Box className={cls.chips} onClick={onClicked}>
        <Forum color="primary" />
        <Box className={cls.number}>{number}</Box>
      </Box>
    </Tooltip>
  ) : (
    <></>
  );
};

const TasksChip = ({ questionId }: { readonly questionId: string }) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const [number, setNumber] = useState<number>(0);
  const { setShowTaskDetails, setSelectedTab, lastCreatedTaskId } = useMetaView();
  const { docTasks } = useTasks();
  useEffect(() => {
    setNumber(genericTasksFilter(docTasks, { questionId })?.length || 0);
  }, [questionId, lastCreatedTaskId, docTasks]);

  const onClicked = useCallback(() => {
    setSelectedTab(META_VIEW_TABS.TODOS);
    setShowTaskDetails(false);
  }, [setSelectedTab, setShowTaskDetails]);

  return number > 0 ? (
    <Tooltip title={t("tasks_page:go_to_tasks")}>
      <Box mr={1} className={cls.chips} onClick={onClicked}>
        <CheckCircle color="primary" />
        <Box className={cls.number}>{number}</Box>
      </Box>
    </Tooltip>
  ) : (
    <></>
  );
};

const RemarksChip = ({ questionId }: { readonly questionId: string }) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const [number, setNumber] = useState<number>(0);
  const { setSelectedTab, setShowTaskDetails } = useMetaView();
  const { auditRemarks } = useAuditRemarks();

  useEffect(() => {
    if (questionId && auditRemarks) {
      const remarks = auditRemarks.filter(remark => remark.questionId === questionId);
      setNumber(remarks.length || 0);
    }
  }, [auditRemarks, questionId]);

  const onClicked = useCallback(() => {
    setSelectedTab(META_VIEW_TABS.REMARKS);
    setShowTaskDetails(false);
  }, [setSelectedTab, setShowTaskDetails]);

  return number > 0 ? (
    <Tooltip title={t("tasks_page:go_to_remarks")}>
      <Box mr={1} className={cls.chips} onClick={onClicked}>
        <Comment color="primary" />
        <Box className={cls.number}>{number}</Box>
      </Box>
    </Tooltip>
  ) : (
    <></>
  );
};

const AuditActionTasksChip = ({ questionId }: { readonly questionId: string }) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const { auditActionTasks, auditActionTasksLoaded } = useAuditActionTasks();
  const [number, setNumber] = useState<number>(0);

  useEffect(() => {
    if (auditActionTasksLoaded) {
      setNumber(((auditActionTasks as Record<string, number>)?.[questionId] as number) || 0);
    }
  }, [auditActionTasks, auditActionTasksLoaded, questionId]);

  return number > 0 ? (
    <Tooltip title={t("auditActionTaskChipTooltip")}>
      <Box mr={1} className={cls.chips}>
        <CheckCircleOutline color="primary" />
        <Box className={cls.number}>{number}</Box>
      </Box>
    </Tooltip>
  ) : (
    <></>
  );
};

const UserAvatarChip = ({ questionId }: { readonly questionId: string }) => {
  const { whereIAmData, updateSelf } = useWhereIAmContext();
  const path = usePathName();
  const { auth } = useAuthentication();
  const { questionId: stateQuestionId } = useMetaView();
  const { getUserNameHook } = useUserAndTenantData();
  const cls = useStyles();

  useEffect(() => {
    if (!(path && stateQuestionId && auth?.uid && questionId)) {
      return;
    }
    if (stateQuestionId !== questionId) {
      return;
    }

    updateSelf({
      path,
      questionId
    });
    return updateWhereIAmContinuously({
      path,
      questionId,
      userId: auth?.uid
    });
  }, [path, auth?.uid, questionId, stateQuestionId, updateSelf]);

  const allUsersAvatarEl =
    whereIAmData &&
    Object.values(whereIAmData)
      .filter(whereIAmData => whereIAmData.questionId === questionId)
      .filter(whereIAmData => new Date().getTime() - whereIAmData.heartbeat.getTime() < 30000)
      .map(whereIAmData => {
        const userName = getUserNameHook(whereIAmData.userId);
        const initials = getUserNameHook(whereIAmData.userId)
          .split(" ")
          .map(name => name.substring(0, 1))
          .map(name => name.toUpperCase());
        return (
          <Avatar key={whereIAmData.userId} style={{ backgroundColor: whereIAmData.hslColor }} alt={userName}>
            <Tooltip title={userName}>
              <Box>{initials}</Box>
            </Tooltip>
          </Avatar>
        );
      });
  return allUsersAvatarEl?.length > 0 ? (
    <Box className={cls.avatar} mr={1}>
      {allUsersAvatarEl?.length > 5 && <AvatarGroup max={5}>{allUsersAvatarEl}</AvatarGroup>}
      {allUsersAvatarEl?.length <= 5 && (
        <Box className={cls.chips} gridGap={4}>
          {allUsersAvatarEl}
        </Box>
      )}
    </Box>
  ) : (
    <></>
  );
};

const AdvancedQuestion = ({
  actions,
  children,
  chips,
  disabled,
  questionId,
  questionName,
  pb,
  pl,
  pr,
  pt,
  title,
  translatable,
  onFocus
}: QuestionProps) => {
  const cls = useStyles();
  const { t } = useTranslation("task_details");
  const params = useParams();
  const documentId = params?.id;
  const hasTranslationFeature = useIsFeaturePresent(FEATURES.DEEP_L);
  const {
    questionId: stateQuestionId,
    setNewTaskForm,
    setNewCommentForm,
    setNewRemarkForm,
    setQuestionId,
    setQuestionName
  } = useMetaView();

  /* ACTIONS */
  const allActions: QuestionAction[] = useMemo(
    () => [
      {
        id: QUESTION_ACTION.TASKS,
        name: t("tasks_page:create_task"),
        icon: <CheckCircle />,
        handle: () => {
          setNewTaskForm(true);
        }
      },
      {
        id: QUESTION_ACTION.COMMENTS,
        name: t("tasks_page:create_comment"),
        icon: <Forum />,
        handle: () => {
          setNewCommentForm(true);
        }
      },
      {
        id: QUESTION_ACTION.REMARKS,
        name: t("tasks_page:create_remark"),
        icon: <Comment />,
        handle: () => {
          setNewRemarkForm(true);
        }
      },
      {
        id: QUESTION_ACTION.TRANSLATE,
        name: t("tasks_page:translate"),
        icon: <TranslateOutlinedIcon />,
        handle: (event: { currentTarget: React.SetStateAction<null> }) => {
          setAnchorTranslate(event.currentTarget);
        }
      }
    ],
    [setNewCommentForm, setNewRemarkForm, setNewTaskForm, t]
  );
  const defaultActions = useMemo(
    () => [QUESTION_ACTION.TASKS, QUESTION_ACTION.COMMENTS, QUESTION_ACTION.TRANSLATE],
    []
  );

  /* CHIPS */
  const allChips = [
    {
      chipType: QUESTION_CHIP.USER_AVATAR,
      element: UserAvatarChip
    },
    {
      chipType: QUESTION_CHIP.TASKS,
      element: TasksChip
    },
    {
      chipType: QUESTION_CHIP.COMMENTS,
      element: CommentsChip
    },
    {
      chipType: QUESTION_CHIP.AUDIT_ACTION_TASKS,
      element: AuditActionTasksChip
    },
    {
      chipType: QUESTION_CHIP.REMARKS,
      element: RemarksChip
    }
  ];
  const defaultChips: QUESTION_CHIP_TYPES[] = [QUESTION_CHIP.USER_AVATAR, QUESTION_CHIP.TASKS, QUESTION_CHIP.COMMENTS];

  /* useState */
  const [focused, setFocused] = useState<boolean>(false);
  const [action, setAction] = useState<boolean>(focused && stateQuestionId === questionId);
  const [open, setOpen] = useState<boolean>(false);
  const [anchorTranslate, setAnchorTranslate] = useState(null);
  const [dialActions, setDialActions] = useState<QuestionAction[]>([]);

  const translationId = `translationId_${documentId}_${questionId}`;
  const [contentToTranslate, setContentToTranslate] = useState("");
  useEffect(() => {
    if (translatable) {
      setContentToTranslate(getTextFromTextEditorJsonString(translatable));
    }
  }, [translatable]);

  /* useEffect */
  useEffect(() => {
    setAction(stateQuestionId === questionId && dialActions?.length > 0);
    if (stateQuestionId === questionId) {
      setQuestionName(questionName || null);
    }
  }, [stateQuestionId, questionId, t, setQuestionName, questionName, dialActions?.length]);

  const onFocusCallback = useCallback(
    (event?: any) => {
      if (!(questionId && !disabled)) {
        return;
      }

      setFocused(true);
      setQuestionId(questionId);
      setQuestionName(questionName || null);
      onFocus?.(event);
    },
    [questionId, disabled, setQuestionId, setQuestionName, questionName, onFocus]
  );

  const titleEl = title && (
    <Box mr={8} className={cls.cursorAuto}>
      <QuestionTitle>{title}</QuestionTitle>
    </Box>
  );

  const chipsEl = (
    <Box className={cls.comments} mr={1}>
      {questionId &&
        (chips || defaultChips).map((chipType: QUESTION_CHIP_TYPES, index: number) => {
          const chip = allChips.find(chip => chip.chipType === chipType);
          if (chip) {
            return React.createElement(chip.element, { questionId, key: index });
          } else return <></>;
        })}
    </Box>
  );

  const hasContentToTranslate = !!contentToTranslate;

  useEffect(() => {
    const resultActions = allActions.filter(({ id }) => {
      if (id === QUESTION_ACTION.TRANSLATE) {
        return hasTranslationFeature && hasContentToTranslate;
      } else {
        return (actions || defaultActions).includes(id);
      }
    });
    setDialActions(resultActions);
  }, [
    actions,
    defaultActions,
    hasContentToTranslate,
    hasTranslationFeature,
    setNewTaskForm,
    setNewCommentForm,
    t,
    setNewRemarkForm,
    allActions
  ]);

  const onMouseLeaveSpeedDial = useCallback(() => {
    setOpen(false);
    setAnchorTranslate(null);
  }, []);
  const onSpeedDialOpened = useCallback(() => {
    setOpen(true);
    setAnchorTranslate(null);
  }, []);
  const speedDial = action && (
    <Box onMouseLeave={onMouseLeaveSpeedDial}>
      <SpeedDial
        ariaLabel=""
        direction="down"
        className={cls.action}
        onOpen={onSpeedDialOpened}
        open={open}
        icon={<SpeedDialIcon />}
      >
        {dialActions.map(({ name, icon, handle }) => (
          <SpeedDialAction key={name} icon={icon} tooltipTitle={name} onClick={handle} />
        ))}
      </SpeedDial>
      <TranslationPopper
        translationId={translationId}
        anchorTranslate={anchorTranslate}
        hasContentToTranslate={hasContentToTranslate}
      />
    </Box>
  );

  return (
    <Box className={cls.root} onClick={onFocusCallback}>
      <Box
        className={`${cls.actionContainer} ${action && cls.focused}`}
        mr={-3}
        pb={pb ?? 3}
        pl={pl ?? 0}
        pr={pr ?? 3}
        pt={titleEl ? 0 : pt ?? 3}
      >
        {chipsEl}
        {speedDial}
        {titleEl}
        {children}
        <TranslationResult translationId={translationId} contentToTranslate={contentToTranslate} />
      </Box>
    </Box>
  );
};

export default AdvancedQuestion;
