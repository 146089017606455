import { createTheme as createMUI4Theme } from "@material-ui/core/styles";
import { createTheme as createMUI5Theme } from "@mui/material/styles";

import palette from "./palette/palette";
import typography from "./typography/typography";
import overrides from "./overrides/overrides";
import shape from "./shapes/shape";
import colors from "./palette/colors";
import breakpoints from "./breakpoints/breakpoints";
import { suisseIntlFont } from "./fonts/fonts";

declare module "@material-ui/core/styles" {
  interface Theme {
    colors: typeof colors;
  }

  interface ThemeOptions {
    colors: typeof colors;
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    colors: typeof colors;
  }

  interface ThemeOptions {
    colors: typeof colors;
  }
}

export default createMUI4Theme({
  colors,
  palette,
  typography,
  overrides,
  breakpoints,
  shape
});

export const mui5Theme = createMUI5Theme({
  colors,
  palette,
  typography,
  breakpoints,
  shape,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'SuisseIntl';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('SuisseIntl'), local('SuisseIntl-Regular'), url(${suisseIntlFont}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: `${colors.grey.grey500} !important` // still doesn't work somehow :(
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: colors.grey.grey400
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: "SuisseIntl"
        }
      }
    }
  }
});
