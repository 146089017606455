import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import { useTranslation } from "react-i18next";
import { COLLECTIONS } from "app/collections";
import Overview from "components/Overview/Overview";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { useMetaView } from "app/contexts/meta-view-context";
import GeneralInformationExport from "./GeneralInformationExport";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useUserDepartments } from "app/contexts/department-context";
import DateDisplay from "components/DateDisplay";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import { ProcessesOverviewCollectionParams } from "./ProcessesOverviewController";
import { exportProcessingActivitiesExcel } from "app/export/createdExcelExportData";
import { FEATURES } from "../../../features";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { exportProcessesPDF } from "app/export/createdPdfExportData";
import { AllProfileData, PROFILE_TYPE } from "app/handlers/tenantProfileInformationHandler";
import { paWritePermissions } from "../../../handlers/permissionHandler";
import { streamFilesToZip } from "../../../handlers/fileHandler";
import { usePAPages } from "./processPages";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";

const ProcessesOverview = ({ dpiaOnly }: { readonly dpiaOnly?: boolean }) => {
  const translationContext = dpiaOnly ? "dpia_overview" : "processes_overview";
  const { t } = useTranslation(translationContext);
  const { setInfoId, setMeta } = useMetaView();
  const { auth } = useAuthentication();
  const { departmentsLoaded } = useUserDepartments();
  useEnteringInfoCard({ pathName: `/processes`, infoId: "infocard.pa.overview.entering" });

  const [generalExportOpen, setGeneralExportOpen] = useState<boolean>(false);
  const [processIdsToExport, setProcessIdsToExport] = useState<string[] | null>(null);

  const showEnterInfo = useCallback(() => {
    setInfoId(dpiaOnly ? "infocard.dpia.overview.entering" : "infocard.pa.overview.entering");
  }, [setInfoId, dpiaOnly]);
  const showCreateInfo = useCallback(() => {
    setInfoId(dpiaOnly ? "infocard.dpia.overview.creating" : "infocard.pa.overview.creating");
  }, [dpiaOnly, setInfoId]);

  const onRowOver = useCallback(
    item => {
      const meta: any = {
        // eslint-disable-next-line camelcase
        dueDate: item.dueAt ? <DateDisplay timestamp={new Date(item.dueAt)} displaySeconds={undefined} /> : "-",
        department: item.subTitle,
        version: item.version ? item.version : "-",
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.createdBy} separator={undefined} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        status: t("status_" + item.status),
        approved: item.approvedAt ? (
          <DateDisplay timestamp={new Date(item.approvedAt)} displaySeconds={undefined} />
        ) : (
          "-"
        ),
        approvedBy: <AutomaticUserDataDisplay uid={item.approvedBy} separator={undefined} />
      };

      if (item.status.toLowerCase() !== "approved") {
        delete meta.approved;
        delete meta.approvedBy;
      }
      setMeta(meta);
    },
    [setMeta, t]
  );

  const toolbarActions = useMemo(() => [{ action: "sort" }, { action: "filter" }, ...[{ action: "export-all" }]], []);

  const paTemplateEnabled = useIsFeaturePresent(FEATURES.PA_TEMPLATES);
  const addActions = useMemo(
    () => [
      { action: OVERVIEW_ADD_TYPE.SINGLE },
      { action: OVERVIEW_ADD_TYPE.MULTIPLE },
      ...(paTemplateEnabled ? [{ action: OVERVIEW_ADD_TYPE.TEMPLATE }] : [])
    ],
    [paTemplateEnabled]
  );

  /* useEffects */
  useEffect(() => {
    return showEnterInfo();
  }, [showEnterInfo]);

  const exportToPdfDialog = useCallback((ids: string[]) => {
    setProcessIdsToExport(ids);
    setGeneralExportOpen(true);
  }, []);

  const exportToPDF = useCallback(
    async (exportInfo: AllProfileData) => {
      await exportProcessesPDF({
        paIds: processIdsToExport || [],
        exportInfo,
        isDPIA: dpiaOnly
      });
      setGeneralExportOpen(false);
    },
    [dpiaOnly, processIdsToExport]
  );

  const exportToXLSX = useCallback(
    async ids => {
      if (!auth?.tenantId) {
        return;
      }

      return await exportProcessingActivitiesExcel(auth?.tenantId, auth?.uid, t, ids);
    },
    [auth?.tenantId, auth?.uid, t]
  );
  const exportToAttachments = useCallback(
    async (ids, idToName) => {
      if (!auth?.tenantId) {
        return;
      }

      await streamFilesToZip({
        exportDefinitions: [
          {
            docIds: ids,
            category: `${COLLECTIONS.PROCESSES}-description`,
            categoryName: "General",
            withPrefixQuery: false,
            docIdToFolderName: id => idToName.get(id) || id
          },
          {
            docIds: ids,
            category: `${COLLECTIONS.PROCESSES}`,
            categoryName: "Data Flows",
            withPrefixQuery: false,
            docIdToFolderName: id => idToName.get(id) || id
          }
        ]
      });
    },
    [auth?.tenantId]
  );
  const rowActions = useMemo(() => [{ action: "edit" }, { action: "remove" }], []);
  const selectionActions = useMemo(
    () => [{ action: "duplicate-copy-to" }, { action: "export-all" }, { action: "remove" }],
    []
  );
  const collectionParams = useMemo<ProcessesOverviewCollectionParams>(
    () => ({
      filter: { dpiaOnly: Boolean(dpiaOnly) },
      exportToPdfDialog: exportToPdfDialog,
      exportToXLSX,
      exportToAttachments
    }),
    [exportToPdfDialog, exportToXLSX, dpiaOnly, exportToAttachments]
  );

  const showAddActions = auth?.permissions.find(permission => paWritePermissions.includes(permission));

  const { pages, onPageChange, shouldShowPages } = usePAPages();

  return (
    <DocMetaView metaViewContent={<MetaView editable={true} translationKey={translationContext} />}>
      {departmentsLoaded && (
        <>
          <Overview
            collection={COLLECTIONS.PROCESSES}
            collectionParams={collectionParams}
            pages={shouldShowPages && !dpiaOnly ? pages : undefined}
            onPageChange={onPageChange}
            onAddOpen={showCreateInfo}
            onAddClose={showEnterInfo}
            onRowOver={onRowOver}
            onRowLeave={showEnterInfo}
            rowActions={rowActions}
            toolbarActions={toolbarActions}
            addActions={showAddActions ? addActions : undefined}
            selectionActions={selectionActions}
            checkable={true}
            header={dpiaOnly ? t("list_dpias") : t("list_processes")}
            toolbarMode={"tabs"}
            showSelectAll={true}
          />
          <GeneralInformationExport
            tenantId={auth?.tenantId || ""}
            open={generalExportOpen}
            setOpen={setGeneralExportOpen}
            exportProcesses={exportToPDF}
            profiles={profilesForExport}
          />
        </>
      )}
    </DocMetaView>
  );
};

const profilesForExport: PROFILE_TYPE[] = [
  "dataController",
  "jointController",
  "controllerRepresentative",
  "dataProtectionOfficer"
];

export default React.memo(ProcessesOverview);
