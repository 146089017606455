import { useCallback } from "react";
import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import {
  getAssignedUserFilter,
  getDPOFilter,
  getLabelsFilter,
  getOrganizationFilter,
  getProcessStatusFilter,
  getProcessTaskFilter,
  getResponsibleExpertFilter
} from "app/pages/shared/Filters/filters";
import { AxiosInstance } from "axios";
import { usersDecorator } from "../../../../components/Overview/controllers/decorator/usersDecorator";
import { resourcesDecorator } from "../../../../components/Overview/controllers/decorator/resourcesDecorator";
import { usePageContextRefresher } from "../../shared/PageTemplate/PageTemplate";
import { useSidebarSWR } from "../../shared/Sidebar/useSidebarUnseen";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";
import { useUserAndTenantData } from "../../../handlers/userAndTenant/user-tenant-context";
import { FEATURES } from "../../../features";
import { chunk } from "lodash-es";

export interface ProcessFilterOptions {
  readonly dpiaOnly: boolean;
  readonly dataLocationIDs: string[];
  readonly assetIDs: string[];
  readonly externalRecipientIDs: string[];
  readonly internalRecipientIDs: string[];
  readonly dataTypeIDs: string[];
  readonly personGroupIDs: string[];
  readonly tomIDs: string[];
  readonly riskIDs: string[];
  readonly legalRetentionPeriodIDs: string[];
  readonly deletionTypeIDs: string[];
  readonly legalBasisIDs: string[];
}

export interface ProcessesOverviewCollectionParams {
  readonly filter?: Partial<ProcessFilterOptions>;
  readonly exportToXLSX?: (ids: string[]) => Promise<void>;
  readonly exportToAttachments?: (ids: string[], idToName: Map<string, string>) => Promise<void>;
  readonly exportToPdfDialog?: (ids: string[]) => void;
  readonly openInNewTab?: boolean;
}

const ProcessesOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: ProcessesOverviewCollectionParams
): OverviewController => {
  const { t } = useTranslation();
  const { unseenTasksMutate } = useSidebarSWR();
  const { tenantData } = useUserAndTenantData();
  const reloadPageContext = usePageContextRefresher();

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.PROCESSES, undefined, [
    usersDecorator,
    resourcesDecorator,
    departmentsDecorator
  ]);

  const getFilters = useCallback(
    (overviewResult: OverviewResult) => {
      return [
        getOrganizationFilter("allOrgUnitIds", overviewResult._departments, t),
        getOrganizationFilter("orgUnitIds", overviewResult._departments, t, "responsibleDepartment"),
        getOrganizationFilter("furtherOrgUnitIds", overviewResult._departments, t, "furtherDepartments"),
        getProcessStatusFilter("status", t),
        getLabelsFilter("labelIds", overviewResult._resources, t),
        getProcessTaskFilter("openTasks", t),
        getResponsibleExpertFilter(
          "responsibleExpertId",
          overviewResult.responsibleExpertIds,
          overviewResult._tenantUsers,
          t
        ),
        getAssignedUserFilter("assignedUserId", overviewResult.assignedUserIds, overviewResult._tenantUsers, t),
        ...(tenantData?.features.includes(FEATURES.DATA_PROTECTION_OFFICER_FIELD)
          ? [getDPOFilter("dpoUID", overviewResult._tenantUsers, overviewResult.dpoUIDs || [], t)]
          : [])
      ];
    },
    [t, tenantData?.features]
  );

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: t("filter_criteria:aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: t("filter_criteria:zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: t("filter_criteria:newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: t("filter_criteria:oldFirst")
      }
    ],
    [t]
  );

  const getOverview = async (setup: OverviewSetup) => {
    const data = await baseController.getOverview(setup, "/", { params: collectionParams?.filter || {}, timeout: 0 });
    if (!data) {
      return null;
    }

    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    const url = `/processes/${id}${collectionParams.filter?.dpiaOnly ? "/proportionality" : "/general"}`;
    if (collectionParams.openInNewTab) {
      window.open(url, "_blank");
      return;
    }
    baseController.goToItem(url);
  };

  const addItem = async (data: OverviewNewItem) => {
    const payload = {
      name: data?.title || createOverviewItemDefaultName("processes"),
      dpiaRequiredDecision: collectionParams.filter?.dpiaOnly ? "yes" : undefined
    };
    const createResponse = await baseController.addItem(payload, `/`);
    unseenTasksMutate(); // creating a PA creates a tasks which should updates the sidebar unseen items
    return createResponse;
  };

  const patchItem = async (id: string, data: object) => {
    const title = ("title" in data && (data.title as string)) || "";
    await baseController.patchItem(id, { name: title });
    await reloadPageContext();
  };

  const deleteItem = async (id: string) => {
    return await baseController.deleteItem(id);
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const response = await addItem(data);
    goToItem(response.headers["x-resource-id"]);
  };

  const addItemsFromTemplates = async (data: { templateIds: string[] }) => {
    const response = await baseController.addItemsFromTemplates(data, `/templates`);
    await reloadPageContext();
    return response;
  };

  const exportItems = async (format: string, ids: string[], setup: OverviewSetup) => {
    if (format === "pdf") {
      await collectionParams.exportToPdfDialog?.(ids);
    } else if (format === "xlsx") {
      await collectionParams.exportToXLSX?.(ids);
    } else if (format === "attachments") {
      const data = await baseController.getOverview(setup);
      const idToName = (data?.allItems || []).reduce<Map<string, string>>((acc, item) => {
        acc.set(item.id, item.title);
        return acc;
      }, new Map<string, string>());
      await collectionParams.exportToAttachments?.(ids, idToName);
    }
  };

  const exportAllItems = async (format: string, setup: OverviewSetup) => {
    const data = await baseController.getOverview(setup);
    const ids = (data?.allItems || []).map(({ id }) => id);
    if (format === "pdf") {
      await collectionParams.exportToPdfDialog?.(ids);
    } else if (format === "xlsx") {
      await collectionParams.exportToXLSX?.(ids);
    } else if (format === "attachments") {
      await exportItems(format, ids, setup);
    }
  };

  return {
    ...baseController,
    getOverview,
    goToItem,
    addItem,
    patchItem,
    deleteItem,
    addItemAndGo,
    addItemsFromTemplates,
    exportItems,
    exportAllItems
  };
};

export default ProcessesOverviewController;
