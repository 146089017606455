import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import OrgUnitTree from "app/pages/shared/OrgUnitPickerModal/OrgUnitTree";
import TextBody2 from "components/TextBody2/TextBody2";
import { useUserDepartments } from "app/contexts/department-context";
import {
  expandOrgUnitIds,
  orgUnitAndChildren,
  orgUnitsToIdMap,
  orgUnitsToParentIdMap
} from "app/handlers/orgUnitHandler";
import { Department } from "app/handlers/departmentHandler";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import Box from "@mui/material/Box";

interface OrgUnitPickerModalProps {
  readonly open: boolean;
  readonly selectedDepartmentIds: string[];
  readonly authorizedOrgUnitIds?: string[];
  readonly availableOrgUnitIds?: string[];
  readonly linkingOrgUnitsModalHeading?: string;
  readonly linkingOrgUnitsModalSubHeading?: string;
  readonly onClose: () => void;
  readonly onConfirm: (departmentIds: string[]) => void;
  readonly required: boolean;
}

export default function OrgUnitPickerModal({
  open,
  selectedDepartmentIds,
  authorizedOrgUnitIds,
  availableOrgUnitIds,
  linkingOrgUnitsModalHeading,
  linkingOrgUnitsModalSubHeading,
  onClose,
  onConfirm,
  required
}: OrgUnitPickerModalProps) {
  const { t } = useTranslation("organisation");

  const { departments } = useUserDepartments();

  /**
   * Visible departments in Tree
   */
  const authorizedDepartments = useMemo<Department[]>(() => {
    if (!departments) {
      return [];
    }
    if (!authorizedOrgUnitIds) {
      return departments;
    }
    if (authorizedOrgUnitIds.includes("*")) {
      return departments;
    }

    return (authorizedOrgUnitIds || []).reduce<Department[]>((acc, next) => {
      return [...acc, ...orgUnitAndChildren(next, departments)];
    }, []);
  }, [departments, authorizedOrgUnitIds]);

  const authorizedDepartmentsMap = useMemo<Map<string, Department>>(
    () => orgUnitsToIdMap(authorizedDepartments),
    [authorizedDepartments]
  );

  const parentToChildAuthorizedDepartments = useMemo<Map<string, Department[]>>(
    () => orgUnitsToParentIdMap(authorizedDepartments),
    [authorizedDepartments]
  );

  /**
   * Non disabled departments in Tree
   */
  const availableDepartmentIds = useMemo<Set<string>>(() => {
    if (!availableOrgUnitIds) {
      return new Set(authorizedDepartments.map(department => department.id));
    }
    if (availableOrgUnitIds.includes("*")) {
      return new Set(authorizedDepartments.map(department => department.id));
    }

    return expandOrgUnitIds(availableOrgUnitIds, parentToChildAuthorizedDepartments);
  }, [availableOrgUnitIds, parentToChildAuthorizedDepartments, authorizedDepartments]);

  const filterCheckedByAvailable = useCallback(
    (departmentId: string) => availableDepartmentIds.has("*") || availableDepartmentIds.has(departmentId),
    [availableDepartmentIds]
  );

  const [checkedDepartmentIds, setCheckedDepartmentIds] = useState<Set<string>>(new Set());
  useEffect(() => {
    setCheckedDepartmentIds(
      new Set(
        selectedDepartmentIds.includes("*")
          ? authorizedDepartments.map(department => department.id)
          : selectedDepartmentIds
      )
    );
  }, [selectedDepartmentIds, authorizedDepartments]);

  const checkedFilteredDepartmentIds = useMemo<string[]>(
    () => [...checkedDepartmentIds].filter(filterCheckedByAvailable),
    [checkedDepartmentIds, filterCheckedByAvailable]
  );

  const confirmSelection = useCallback(() => {
    onConfirm(checkedFilteredDepartmentIds);
    onClose();
  }, [onConfirm, checkedFilteredDepartmentIds, onClose]);

  const buttons = useMemo(
    () =>
      [
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onClose
        },
        {
          confirmButton: true,
          title: t("common:confirm"),
          variant: "contained",
          color: "primary",
          size: "medium",
          disabled: required ? checkedFilteredDepartmentIds.length < 1 : false,
          onClick: confirmSelection
        }
      ] as ConfirmationModalButtonProps[],
    [checkedFilteredDepartmentIds.length, confirmSelection, onClose, t, required]
  );

  const stopEventPropagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);

  const modalBody = useMemo(
    () => (
      <Box height={400} overflow={"auto"} onClick={stopEventPropagation}>
        <Box mb={2}>
          <TextBody2 text={linkingOrgUnitsModalSubHeading || t("resources_overview:linkingModalSubHeading")} />
        </Box>
        <OrgUnitTree
          allDepartments={authorizedDepartmentsMap}
          parentToChildDepartments={parentToChildAuthorizedDepartments}
          availableDepartmentIds={availableDepartmentIds}
          checkedDepartmentIds={checkedDepartmentIds}
          onCheckedChanged={setCheckedDepartmentIds}
        />
      </Box>
    ),
    [
      stopEventPropagation,
      linkingOrgUnitsModalSubHeading,
      t,
      authorizedDepartmentsMap,
      parentToChildAuthorizedDepartments,
      availableDepartmentIds,
      checkedDepartmentIds
    ]
  );

  return (
    <ConfirmationModal
      variant={"info"}
      modalBody={modalBody}
      modalOpen={open}
      modalTitle={linkingOrgUnitsModalHeading || t("resources_overview:linkingModalHeading")}
      onClose={onClose}
      buttons={buttons}
      modalText={""}
    />
  );
}
