import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import ListViewItem from "components/ListViewItem/ListViewItem";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { CustomRowComponentProps } from "components/Overview/controls/OverviewRow";
import { useNavigate } from "react-router-dom";
import { Box, Chip } from "@mui/material";
import { useOverviewState } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";
import { triggerResetPasswordApi } from "app/api/user/userPasswordResetApi";
import { useSnackbar } from "notistack";
import { chipSx } from "components/Overview/collections/assets/AssetsOverviewRow";

export default function UsersOverviewRow({
  checkable,
  checked,
  item,
  onChecked,
  onDelete
}: CustomRowComponentProps<OverviewItem>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const overviewSetup = useOverviewState()[COLLECTIONS.USER];
  const { enqueueSnackbar } = useSnackbar();
  const deleteItem = useCallback(async () => {
    await onDelete(item.id);
  }, [item.id, onDelete]);

  const deleteDisabled = useMemo(
    () => item.disableActions?.find(({ action }) => action === "remove"),
    [item.disableActions]
  );

  const handleClick = useCallback(() => {
    navigate(`/users/${item.id}`);
  }, [item.id, navigate]);

  const sendResetEmail = useCallback(
    async (selectedUserEmail: string) => {
      if (!selectedUserEmail) {
        throw new Error("Selected user have no email");
      }

      try {
        await triggerResetPasswordApi({ email: selectedUserEmail });
        enqueueSnackbar(t("manage-user-page:user_resetted"), { variant: "success" });
      } catch (error) {
        console.error("failure on sending password reset email", error);
      }
    },
    [enqueueSnackbar, t]
  );

  const resetHandler = useCallback(
    async id => {
      await sendResetEmail(item.email);
    },
    [sendResetEmail, item.email]
  );

  const rowActions = useMemo(
    () => [
      {
        action: "reset",
        title: t("manage-user-page:reset"),
        icon: <VpnKeyIcon />,
        onHandle: resetHandler,
        item
      }
    ],
    [t, resetHandler, item]
  );

  const userTypeLabel = useMemo(
    () =>
      item?.userRole === "externalUser" && (
        <Chip label={t(`add_participant:participant_type_external`).toLocaleUpperCase()} sx={chipSx} />
      ),
    [item.userRole, t]
  );

  return (
    <Box
      onClick={handleClick}
      sx={{
        cursor: "pointer"
      }}
    >
      <ListViewItem
        deletable={!deleteDisabled}
        onDelete={deleteItem}
        onSelect={checkable ? onChecked : undefined}
        rowActions={rowActions}
        searchText={overviewSetup.search}
        selected={checked}
        subTitle={item.subTitle}
        title={item.title}
      >
        {userTypeLabel}
      </ListViewItem>
    </Box>
  );
}
