import {
  addToSeenItemsOfUser,
  getSeenItemsOfUsers,
  removeFromSeenItemsOfUser,
  UserSeenItem
} from "app/handlers/userAndTenant/userSeenItemsHandler";
import { ASSIGNABLE_USER_FIELD, SingleTenantNewUser, SingleTenantUpdateUser, UserDTO } from "app/api/user/userApi";
import { getTenantInformation, Tenant } from "app/handlers/tenantHandler";
import {
  EmailNotificationConfig,
  getEmailNotificationConfig,
  updateEmailNotificationConfig
} from "./userEmailNotificationHandler";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthentication } from "../authentication/authentication-context";
import { createUser, deleteUser, getUserData, getUsers, updateUser } from "./userHandler";
import { updateMeApi } from "app/api/user/userMeApi";
import { allAvailableLanguages } from "app/handlers/languageHandler";
import featuresEn from "assets/i18n/features_en.json";
import featuresDe from "assets/i18n/features_de.json";
import featuresCz from "assets/i18n/features_cz.json";
import featuresPl from "assets/i18n/features_pl.json";
import featuresDa from "assets/i18n/features_da.json";
import featuresEs from "assets/i18n/features_es.json";
import featuresIt from "assets/i18n/features_it.json";
import featuresDeCH from "assets/i18n/features_de-CH.json";
import i18n from "app/i18n";
import { getUserName, getUserNameEmail } from "../../utils/get-user-name";
import { withAbortController } from "../../api/axios/axiosErrorHandler";
import { FEATURES } from "app/features";
import useSWR from "swr";
import { NotLoggedInError } from "../authentication/authenticationError";

export interface UserAndTenantDataContextType {
  readonly loading: boolean;
  readonly seenItems: UserSeenItem[];
  readonly nonRootAdminTenantUsers: UserDTO[];
  readonly tenantData: Tenant | null;
  getUserNameHook: (id: string) => string;
  getUserNameEmailHook: (id: string) => string;
  getUserHook: (userId: string) => UserDTO | null;
  createUserHook: (input: SingleTenantNewUser) => Promise<string>;
  updateUserHook: (userId: string, input: SingleTenantUpdateUser) => Promise<void>;
  deleteUserHook: (userId: string) => Promise<void>;
  getLatestUserHook: (userId: string) => Promise<UserDTO | null>;
  updateMyNameHook: (firstName?: string, lastName?: string) => Promise<void>;
  addToSeenItemsOfUserHook: (collection: string, newItemId: string) => Promise<void>;
  loadSeenItemsOfUserHook: () => Promise<void>;
  removeFromSeenItemsOfUserHook: (userId: string, collection: string, newItemId: string) => Promise<void>;
  getEmailNotificationConfigHook: () => Promise<EmailNotificationConfig>;
  updateEmailNotificationConfigHook: (input: Partial<EmailNotificationConfig>) => Promise<void>;
  getUsersByAssignableFieldHook: (assignmentType?: ASSIGNABLE_USER_FIELD) => Promise<UserDTO[]>;
  reloadTenantUsers: () => void;
}

export const UserAndTenantDataContext = createContext<UserAndTenantDataContextType>({
  loading: true,
  seenItems: [],
  nonRootAdminTenantUsers: [],
  tenantData: null,
  getUserNameHook: id => "",
  getUserNameEmailHook: id => "",
  createUserHook: async () => {
    throw new Error("Not implemented");
  },
  updateUserHook: async () => {
    throw new Error("Not implemented");
  },
  deleteUserHook: async () => {
    throw new Error("Not implemented");
  },
  getUserHook: () => {
    throw new Error("Not implemented");
  },
  getLatestUserHook: async () => {
    throw new Error("Not implemented");
  },
  updateMyNameHook: async () => {
    throw new Error("Not implemented");
  },
  addToSeenItemsOfUserHook: async () => {
    throw new Error("Not implemented");
  },
  removeFromSeenItemsOfUserHook: async () => {
    throw new Error("Not implemented");
  },
  getEmailNotificationConfigHook: async () => {
    throw new Error("Not implemented");
  },
  updateEmailNotificationConfigHook: async () => {
    throw new Error("Not implemented");
  },
  loadSeenItemsOfUserHook: async () => {
    throw new Error("Not implemented");
  },
  getUsersByAssignableFieldHook: async () => {
    throw new Error("Not implemented");
  },
  reloadTenantUsers: async () => {
    throw new Error("Not implemented");
  }
});

export const UserAndTenantDataProvider = ({ children }: { children: React.ReactNode }) => {
  const { auth } = useAuthentication();
  const { data: seenItems, mutate: mutateSeenItems } = useSWR(
    auth?.tenantId && auth?.uid ? [auth.tenantId, auth.uid] : null,
    ([tenantId, userId]) => getSeenItemsOfUsers(tenantId, userId)
  );
  const [tenantUsers, setTenantUsers] = useState<UserDTO[]>([]);
  const [nonRootAdminTenantUsers, setNonRootAdminTenantUsers] = useState<UserDTO[]>([]);
  const { data: tenantData } = useSWR<Tenant | null>(auth?.tenantId || null, (tenantId: string) =>
    getTenantInformation(tenantId)
  );
  const [loading, setLoading] = useState(true);

  const [abortReloadUserFn, setAbortReloadUserFn] = useState<() => void>(() => () => {
    /* empty */
  });
  const reloadTenantUsers = useCallback(() => {
    setAbortReloadUserFn(lastAbortReloadUser => {
      lastAbortReloadUser();
      return withAbortController({
        executeFn: abortController => getUsers({ abortController }).then(setTenantUsers)
      });
    });
  }, []);

  const getUsersWithoutRootAdmin = useCallback(
    (tenantUsers: UserDTO[]) => {
      const isCaralegalTenantAdmin = (user: UserDTO): boolean =>
        (user.email?.endsWith("@caralegal.eu") && user.userRole === "tenantAdmin") || false;
      return tenantUsers.filter(user => user.id === auth?.uid || !isCaralegalTenantAdmin(user));
    },
    [auth?.uid]
  );

  useEffect(() => {
    setNonRootAdminTenantUsers(getUsersWithoutRootAdmin(tenantUsers));
  }, [tenantUsers, auth?.uid, getUsersWithoutRootAdmin]);

  useEffect(() => {
    if (!auth?.tenantId || !auth?.uid) {
      return;
    }

    reloadTenantUsers();

    setLoading(false);
  }, [auth?.uid, auth?.tenantId, reloadTenantUsers]);

  useEffect(() => {
    if (tenantData?.features) {
      appendFeaturesI18n(tenantData.features);
    }
  }, [tenantData?.features]);

  const withReloadUsers = useCallback(
    async (changerFn: () => Promise<any>) => {
      setLoading(true);
      const result = await changerFn().catch((error: any) => {
        setLoading(false);
        throw error;
      });
      reloadTenantUsers();
      setLoading(false);
      return result;
    },
    [setLoading, reloadTenantUsers]
  );

  const getUsersByAssignableFieldHook = useCallback(
    async (assignmentType?: ASSIGNABLE_USER_FIELD) => {
      if (assignmentType) {
        return nonRootAdminTenantUsers.filter(
          user => user.assignableFields?.includes(assignmentType) && user.userRole !== "externalUser"
        );
      } else return nonRootAdminTenantUsers.filter(user => user.userRole !== "externalUser");
    },
    [nonRootAdminTenantUsers]
  );

  const createUserHook = useCallback(
    (input: SingleTenantNewUser) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      return withReloadUsers(() => createUser(auth?.tenantId, input));
    },
    [withReloadUsers, auth?.tenantId]
  );

  const updateUserHook = useCallback(
    (userId: string, input: SingleTenantUpdateUser) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      return withReloadUsers(() => updateUser(userId, input));
    },
    [withReloadUsers, auth?.tenantId]
  );
  const deleteUserHook = useCallback(
    (userId: string) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      return withReloadUsers(() => deleteUser(userId));
    },
    [withReloadUsers, auth?.tenantId]
  );

  const updateMyNameHook = useCallback(
    (firstName?: string, lastName?: string) => {
      return withReloadUsers(() =>
        updateMeApi({
          firstName,
          lastName
        })
      );
    },
    [withReloadUsers]
  );
  const addToSeenItemsOfUserHook = useCallback(
    async (collection: string, newItemId: string) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      await addToSeenItemsOfUser(auth.tenantId, auth.uid, collection, newItemId);
      await mutateSeenItems(
        it => {
          return it;
        },
        {
          revalidate: false,
          populateCache: false,
          optimisticData: (lastData, lastDisplayData) => {
            if (!lastDisplayData) {
              return [];
            }

            return [
              ...lastDisplayData,
              {
                collection,
                itemId: newItemId,
                id: newItemId,
                created: new Date()
              }
            ];
          }
        }
      );
    },
    [auth?.tenantId, auth?.uid, mutateSeenItems]
  );

  const loadSeenItemsOfUserHook = useCallback(async () => {
    if (!auth?.tenantId) {
      throw new NotLoggedInError();
    }
    await mutateSeenItems();
  }, [auth?.tenantId, mutateSeenItems]);

  const removeFromSeenItemsOfUserHook = useCallback(
    async (userId: string, collection: string, itemId: string) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }
      await removeFromSeenItemsOfUser(auth.tenantId, userId, collection, itemId);
      await mutateSeenItems(
        it => {
          return it;
        },
        {
          revalidate: false,
          populateCache: false,
          optimisticData: (lastData, lastDisplayData) => {
            if (!lastDisplayData) {
              return [];
            }

            return lastDisplayData.filter(it => !(it.collection === collection && it.itemId === itemId));
          }
        }
      );
    },
    [auth?.tenantId, mutateSeenItems]
  );
  const getEmailNotificationConfigHook = useCallback(async () => {
    if (!auth?.tenantId) {
      throw new NotLoggedInError();
    }

    return getEmailNotificationConfig(auth.tenantId, auth.uid);
  }, [auth?.tenantId, auth?.uid]);

  const updateEmailNotificationConfigHook = useCallback(
    async (configData: Partial<EmailNotificationConfig>) => {
      if (!auth?.tenantId) {
        throw new NotLoggedInError();
      }

      await updateEmailNotificationConfig(auth.tenantId, auth.uid, configData);
    },
    [auth?.tenantId, auth?.uid]
  );

  const getUserHook = useCallback(
    (id: string) => {
      return tenantUsers.find(user => user.id === id) || null;
    },
    [tenantUsers]
  );

  const getUserNameHook = useCallback(
    (id: string) => {
      return getUserName(tenantUsers, id);
    },
    [tenantUsers]
  );

  const getUserNameEmailHook = useCallback(
    (id: string) => {
      return getUserNameEmail(tenantUsers, id);
    },
    [tenantUsers]
  );

  const getLatestUserHook = useCallback(
    (id: string) => {
      const groupFeaturesEnabled = tenantData?.features.includes(FEATURES.USERGROUPS_FEATURES);
      return getUserData(id, groupFeaturesEnabled ?? false);
    },
    [tenantData]
  );

  return (
    <UserAndTenantDataContext.Provider
      value={{
        loading,
        seenItems: seenItems ?? [],
        nonRootAdminTenantUsers,
        tenantData: tenantData ?? null,
        getUserNameHook,
        getUserNameEmailHook,
        createUserHook,
        updateUserHook,
        deleteUserHook,
        getUserHook,
        getLatestUserHook,
        updateMyNameHook,
        addToSeenItemsOfUserHook,
        loadSeenItemsOfUserHook,
        removeFromSeenItemsOfUserHook,
        getEmailNotificationConfigHook,
        updateEmailNotificationConfigHook,
        getUsersByAssignableFieldHook,
        reloadTenantUsers
      }}
    >
      {children}
    </UserAndTenantDataContext.Provider>
  );
};

// visible for testing
export const appendFeaturesI18n = (userFeatures: string[]) => {
  userFeatures.forEach(feature => {
    allAvailableLanguages.forEach(lang => {
      let featureI18n: any = {};
      switch (lang) {
        case "en":
          featureI18n = featuresEn;
          break;
        case "de":
          featureI18n = featuresDe;
          break;
        case "cz":
          featureI18n = featuresCz;
          break;
        case "pl":
          featureI18n = featuresPl;
          break;
        case "da":
          featureI18n = featuresDa;
          break;
        case "es":
          featureI18n = featuresEs;
          break;
        case "it":
          featureI18n = featuresIt;
          break;
        case "de-CH":
          featureI18n = featuresDeCH;
          break;
        default:
          featureI18n = featuresDe;
      }
      const extractedI18n = featureI18n[feature];

      if (!extractedI18n) {
        return;
      }

      const nameSpaces = Object.entries(extractedI18n);
      nameSpaces.forEach(([ns, keys]) => {
        i18n.addResources(lang, ns, keys);
      });
    });
  });
};

export const useUserAndTenantData = () => useContext(UserAndTenantDataContext);
