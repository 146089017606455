import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useTranslation } from "react-i18next";
import { Department, getOrgUnits } from "../handlers/departmentHandler";
import { createNaturalSorter } from "../utils/naturalSort";
import { expandOrgUnitIds } from "../handlers/orgUnitHandler";

export interface DepartmentContextValue {
  readonly departmentsLoaded: boolean;
  readonly departments: Department[];
  readonly authUserSelectableDepartmentIds: string[];
  readonly reloadDepartments: () => Promise<void>;
  readonly rootDepartment: Department | null;
  readonly getDepartmentWithParentName: (departmentId: string) => string;
  readonly getDepartmentName: (departmentId: string) => string;
  readonly getDepartmentRecursively: (departmentId: string) => Department[];
  readonly isPartOfUserDepartments: (...departmentIds: string[]) => boolean;
  readonly expandDepartmentIds: (departmentIds: string[]) => Set<string>;
}

export const DepartmentContext = createContext<DepartmentContextValue>({
  departments: [],
  authUserSelectableDepartmentIds: [],
  departmentsLoaded: false,
  getDepartmentName: (departmentId: string): string => departmentId,
  getDepartmentWithParentName: (departmentId: string): string => departmentId,
  getDepartmentRecursively: (): Department[] => [],
  reloadDepartments: (): Promise<void> => Promise.resolve(undefined),
  isPartOfUserDepartments: (): boolean => false,
  expandDepartmentIds: (departmentIds: string[]) => new Set(departmentIds),
  rootDepartment: null
});

export const getDepartments = async (): Promise<Department[]> => {
  return await getOrgUnits();
};

export const DepartmentProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation("");
  const [departmentsLoaded, setDepartmentsLoaded] = useState(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [departmentsMap, setDepartmentsMap] = useState<Map<string, Department>>(new Map());
  const [departmentsWithParentNameMap, setDepartmentsWithParentNameMap] = useState<Map<string, string>>(new Map());
  const [parentToChildrenDepartmentsMap, setParentToChildrenDepartmentsMap] = useState<Map<string, Department[]>>(
    new Map()
  );
  const [rootDepartment, setRootDepartment] = useState<Department | null>(null);

  const { auth } = useAuthentication();
  const uid = auth?.uid || null;
  const tenantId = auth?.tenantId || null;

  const reloadDepartments = useCallback(async () => {
    if (!uid || !tenantId) {
      return;
    }

    const nonDeletedDepartments = await getDepartments();
    const { departmentsMap, parentToChildrenDepartmentsMap, rootDepartment } = toEfficientAccess(nonDeletedDepartments);
    const { departmentsWithParentNameMap } = toEfficientAccessWithParentName(nonDeletedDepartments, departmentsMap);
    setDepartments(nonDeletedDepartments);
    setDepartmentsMap(departmentsMap);
    setParentToChildrenDepartmentsMap(parentToChildrenDepartmentsMap);
    setRootDepartment(rootDepartment);
    setDepartmentsLoaded(true);
    setDepartmentsWithParentNameMap(departmentsWithParentNameMap);
  }, [uid, tenantId]);

  useEffect(() => {
    reloadDepartments();
  }, [reloadDepartments]);

  const getDepartment = useCallback(
    (departmentId: string) => departmentsMap.get(departmentId) || null,
    [departmentsMap]
  );

  const getDepartmentWithParentName = useCallback(
    (departmentId: string) => {
      const department = departmentsMap.get(departmentId);
      if (!department) {
        return t("questionnaires:deletedEntry");
      }

      return department.fullName;
    },
    [t, departmentsMap]
  );

  const getDepartmentName = useCallback(
    (departmentId: string) => {
      return getDepartment(departmentId)?.name || "";
    },
    [getDepartment]
  );

  const getDepartmentRecursively = useCallback(
    (departmentId: string) => {
      const getChildrenOf = (parentId: string): Department[] => {
        return parentToChildrenDepartmentsMap.get(parentId) || [];
      };

      const getRecursive = (parentId: string): Department[] => {
        const parentDepartment = getDepartment(parentId);
        if (!parentDepartment) {
          return [];
        }

        return [parentDepartment, ...getChildrenOf(parentId).flatMap(department => getRecursive(department.id))];
      };

      return getRecursive(departmentId);
    },
    [getDepartment, parentToChildrenDepartmentsMap]
  );

  const [authUserDepartments, setAuthUserDepartments] = useState<Map<string, Department>>(new Map());
  useEffect(() => {
    if (!auth?.orgUnitId) {
      setAuthUserDepartments(new Map());
      return;
    }
    const authUserDepartments = [auth.orgUnitId, ...(auth.furtherOrgUnitIds || [])].flatMap(getDepartmentRecursively);
    setAuthUserDepartments(
      authUserDepartments.reduce((map, department) => map.set(department.id, department), new Map<string, Department>())
    );
  }, [auth?.orgUnitId, auth?.furtherOrgUnitIds, getDepartmentRecursively]);

  const isPartOfUserDepartments = useCallback(
    (...departmentIds: string[]): boolean => {
      if (!auth?.isUserDepartmentBound) {
        return true;
      }

      // wildcard always returns true
      if (departmentIds.includes("*")) {
        return true;
      }

      return departmentIds.some(departmentId => authUserDepartments.has(departmentId));
    },
    [auth?.isUserDepartmentBound, authUserDepartments]
  );

  const [authUserSelectableDepartmentIds, setAuthUserSelectableDepartmentIds] = useState<string[]>([]);
  useEffect(() => {
    const naturalSorter = createNaturalSorter();
    const authUserSelectableDepartmentIds = departments
      .filter(it => isPartOfUserDepartments(it.id))
      .sort((a, b) => naturalSorter(a.fullName, b.fullName))
      .map(it => it.id);
    setAuthUserSelectableDepartmentIds(authUserSelectableDepartmentIds);
  }, [departments, isPartOfUserDepartments, getDepartmentWithParentName]);

  const parentIdToOrgUnits = useMemo<Map<string, Department[]>>(() => {
    const result = new Map<string, Department[]>();
    for (const department of departments) {
      const parentId = department.parentId || "";
      const existing = result.get(parentId) || [];
      existing.push(department);
      result.set(parentId, existing);
    }
    return result;
  }, [departments]);

  const expandDepartmentIds = useCallback(
    departmentIds => {
      return expandOrgUnitIds(departmentIds, parentIdToOrgUnits);
    },
    [parentIdToOrgUnits]
  );

  const [contextValue, setContextValue] = useState<DepartmentContextValue>({
    departmentsLoaded,
    departments,
    authUserSelectableDepartmentIds,
    reloadDepartments,
    rootDepartment,
    getDepartmentWithParentName,
    getDepartmentName,
    getDepartmentRecursively,
    isPartOfUserDepartments,
    expandDepartmentIds
  });
  useEffect(() => {
    setContextValue({
      departmentsLoaded,
      departments,
      authUserSelectableDepartmentIds,
      reloadDepartments,
      rootDepartment,
      getDepartmentWithParentName,
      getDepartmentName,
      getDepartmentRecursively,
      isPartOfUserDepartments,
      expandDepartmentIds
    });
  }, [
    departmentsLoaded,
    departments,
    authUserSelectableDepartmentIds,
    reloadDepartments,
    rootDepartment,
    getDepartmentWithParentName,
    getDepartmentName,
    getDepartmentRecursively,
    isPartOfUserDepartments,
    expandDepartmentIds
  ]);

  return <DepartmentContext.Provider value={contextValue}>{children}</DepartmentContext.Provider>;
};

export const useUserDepartments = () => useContext(DepartmentContext);

const toEfficientAccess = (departments: Department[]) => {
  const departmentsMap = new Map<string, Department>();
  const parentToChildrenDepartmentsMap = new Map<string, Department[]>();
  let rootDepartment: Department | null = null;
  for (const department of departments) {
    departmentsMap.set(department.id, department);
    if (!department.parentId) {
      rootDepartment = department;
      continue;
    }

    const existingChildrenDepartments = parentToChildrenDepartmentsMap.get(department.parentId) || [];
    parentToChildrenDepartmentsMap.set(department.parentId, [...existingChildrenDepartments, department]);
  }
  return { departmentsMap, parentToChildrenDepartmentsMap, rootDepartment };
};

const toEfficientAccessWithParentName = (departments: Department[], departmentsMap: Map<string, Department>) => {
  const departmentsWithParentNameMap = new Map<string, string>();
  const getDepartmentAndParents = (departmentId: string) => {
    let currentDepartment = departmentsMap.get(departmentId) || null;
    const departments = currentDepartment ? [currentDepartment] : [];
    while (currentDepartment?.parentId) {
      currentDepartment = departmentsMap.get(currentDepartment?.parentId) || null;
      if (currentDepartment) {
        departments.push(currentDepartment);
      }
    }
    return departments;
  };

  const getDepartmentWithParentName = (departmentId: string) => {
    const departmentWithParents = getDepartmentAndParents(departmentId);
    const isFound = departmentWithParents.find(department => department.id === departmentId);
    return isFound || departmentId === ""
      ? departmentWithParents
          .filter(department => department.name)
          .map(department => department.name)
          .reverse()
          .join(" > ")
      : "deletedEntry";
  };
  for (const department of departments) {
    departmentsWithParentNameMap.set(department.id, getDepartmentWithParentName(department.id));
  }
  return { departmentsWithParentNameMap };
};
